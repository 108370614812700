import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FinancialMovement } from '../_models/financial-movement';
import { environment } from '../../environments/environment';
import { CostPieChart } from '../_models/cost-pie-chart';

@Injectable()
export class FinancialMovementService {
    constructor(private http: HttpClient) { }

    async save(financialMovement: any){
        return await this.http.post<any[]>(environment.apiUrl + 'financialmovements', financialMovement).toPromise();
    }

    getByBalanceCompanyId(companyId: any){
        return this.http.get<any>(environment.apiUrl + 'financialmovements/' + companyId + '/value');
    }

    getByCompanyId(companyId: any){
        return this.http.get<FinancialMovement[]>(environment.apiUrl + 'financialmovements?company_id=' + companyId);
    }

    async getNewNoteBySupplierId(supplierId: any){
        return this.http.get<any>(environment.apiUrl + 'financialmovements/supplier/' + supplierId + "/newnote").toPromise();
    }

    async getNewNoteByCustomerId(customerId: any){
        return this.http.get<any>(environment.apiUrl + 'financialmovements/customer/' + customerId + "/newnote").toPromise();
    }

    getCostsIdByTypeByCompanyId(companyId: any){
        return this.http.get<CostPieChart[]>(environment.apiUrl + 'x8/' + companyId + '/costpiechart');
    }

    async getByFilter(filter: any){
        return await this.http.post<any>(environment.apiUrl + 'financialmovements/filter', filter).toPromise();
    }

    getBalanceByPlatformId(platformId: any) {
        return this.http.get<any>(environment.apiUrl + 'marketplacesource/balance?platform_id=' + platformId)
    }

    update(financialMovement: FinancialMovement) {
        return this.http.put<any>(environment.apiUrl + 'financialmovements', financialMovement);
    }

    compesateBatch(financialMovement: any) {
        return this.http.put<any>(environment.apiUrl + 'financialmovements/batchcompensation', financialMovement);
    }

    async delete(financialMovement: FinancialMovement) {
        return this.http.delete<any>(environment.apiUrl + 'financialmovements/' + financialMovement.id).toPromise();
    }
    async getCountByFilter(filter: any) {
        return this.http.post<any>(environment.apiUrl + 'financialmovements/count', filter).toPromise();
    }
}
