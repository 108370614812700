interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}


export const navItems: NavData[] = [
  {
    name: 'Início',
    url: '/home',
    icon: 'fa fa-home'
  },
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fa fa-dashboard'
  },
  {
    name: 'Vendas',
    url: '/orders',
    icon: 'fa fa-shopping-cart',
    class: "menu-group-header",
    children: [
      {
        name: 'Pedidos',
        url: '/orderoperational',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Notas Fiscais',
        url: '/invoices',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Devoluções',
        url: '/devolution',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Análise',
        url: '/order',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      }
    ]
  },
  {
    icon: 'fa fa-cubes',
    name: 'Compras',
    url: '/operations',
    class: "menu-group-header",
    children: [
      {
        name: 'Pedidos',
        url: '/purchases',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Ordens',
        url: '/purchaseorders/list',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      }
    ]
  },
  {
    icon: 'fa fa-search-plus',
    name: 'Controle',
    url: 'control',
    class: "menu-group-header",
    children: [
      {
        name: 'Pendências',
        url: '/pendencies',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Estoque',
        url: '/stockmovement',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      }
    ]
  },
  {
    icon: 'fa fa-dollar',
    name: 'Financeiro',
    url: '/financial',
    class: "menu-group-header",
    children: [
      {
        name: 'Contas a Pagar',
        url: '/billstopay',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Contas a Receber',
        url: '/billstoreceive',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Extrato',
        url: '/extract',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Movimentos Por Tipo',
        url: '/movementsbytype',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      }
    ]
  },
  {
    icon: 'fa fa-edit',
    name: 'Cadastro',
    url: '/record',
    class: "menu-group-header",
    children: [
      {
        name: 'Anúncios',
        url: '/announcement',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Respostas Rápidas',
        url: '/quick-answer',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Categorias',
        url: '/categories',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Produtos',
        url: '/products',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Clientes',
        url: '/customers',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Fornecedores',
        url: '/suppliers',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Formas de Pagamento',
        url: '/paymentmethod',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Mensagens Automáticas',
        url: '/automaticmessage',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Contas Correntes',
        url: '/bankaccount',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Tipos de Movimento',
        url: '/movementtypes',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Grupos de Tipos de Movimento',
        url: '/movementtypegroups',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Portadores de Cheques',
        url: '/bankdraftholders/list',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Cheques',
        url: '/bankdrafts/list',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Estoques',
        url: '/stocks',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      }
    ]
  },
  {
    icon: 'fa fa-gear',
    name: 'Configurações',
    url: '/settings',
    class: "menu-group-header",
    children: [
      {
        name: 'Canais de Venda',
        url: '/saleschannels/list',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Certificados Digitais',
        url: '/digitalcertificates',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Preferências',
        url: '/company-preferences',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Perfil de Usuário',
        url: '/profile',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Usuários',
        url: '/users',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Grupos de Usuários',
        url: '/users-groups',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      },
      {
        name: 'Impressoras',
        url: '/printers',
        icon: "fa fa-caret-right",
        class: "sidebar-submenu"
      }
      
    ]
  }
];

  /*{
    name: 'Anúncios',
    url: '/',
    icon: 'icon-feed'
  },
  {
    title: true,
    name: 'Theme'
  },
  {
    name: 'Colors',
    url: '/theme/colors',
    icon: 'icon-drop'
  },
  {
    name: 'Typography',
    url: '/theme/typography',
    icon: 'icon-pencil'
  },
  {
    title: true,
    name: 'Components'
  },
  {
    name: 'Base',
    url: '/base',
    icon: 'icon-puzzle',
    children: [
      {
        name: 'Cards',
        url: '/base/cards',
        icon: 'icon-puzzle'
      },
      {
        name: 'Carousels',
        url: '/base/carousels',
        icon: 'icon-puzzle'
      },
      {
        name: 'Collapses',
        url: '/base/collapses',
        icon: 'icon-puzzle'
      },
      {
        name: 'Forms',
        url: '/base/forms',
        icon: 'icon-puzzle'
      },
      {
        name: 'Pagination',
        url: '/base/paginations',
        icon: 'icon-puzzle'
      },
      {
        name: 'Popovers',
        url: '/base/popovers',
        icon: 'icon-puzzle'
      },
      {
        name: 'Progress',
        url: '/base/progress',
        icon: 'icon-puzzle'
      },
      {
        name: 'Switches',
        url: '/base/switches',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tables',
        url: '/base/tables',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tabs',
        url: '/base/tabs',
        icon: 'icon-puzzle'
      },
      {
        name: 'Tooltips',
        url: '/base/tooltips',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'Buttons',
    url: '/buttons',
    icon: 'icon-cursor',
    children: [
      {
        name: 'Buttons',
        url: '/buttons/buttons',
        icon: 'icon-cursor'
      },
      {
        name: 'Dropdowns',
        url: '/buttons/dropdowns',
        icon: 'icon-cursor'
      },
      {
        name: 'Brand Buttons',
        url: '/buttons/brand-buttons',
        icon: 'icon-cursor'
      }
    ]
  },
  {
    name: 'Charts',
    url: '/charts',
    icon: 'icon-pie-chart'
  },
  {
    name: 'Icons',
    url: '/icons',
    icon: 'icon-star',
    children: [
      {
        name: 'CoreUI Icons',
        url: '/icons/coreui-icons',
        icon: 'icon-star',
        badge: {
          variant: 'success',
          text: 'NEW'
        }
      },
      {
        name: 'Flags',
        url: '/icons/flags',
        icon: 'icon-star'
      },
      {
        name: 'Font Awesome',
        url: '/icons/font-awesome',
        icon: 'icon-star',
        badge: {
          variant: 'secondary',
          text: '4.7'
        }
      },
      {
        name: 'Simple Line Icons',
        url: '/icons/simple-line-icons',
        icon: 'icon-star'
      }
    ]
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    children: [
      {
        name: 'Alerts',
        url: '/notifications/alerts',
        icon: 'icon-bell'
      },
      {
        name: 'Badges',
        url: '/notifications/badges',
        icon: 'icon-bell'
      },
      {
        name: 'Modals',
        url: '/notifications/modals',
        icon: 'icon-bell'
      }
    ]
  },
  {
    name: 'Widgets',
    url: '/widgets',
    icon: 'icon-calculator',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    divider: true
  },
  {
    title: true,
    name: 'Extras',
  },
  {
    name: 'Pages',
    url: '/pages',
    icon: 'icon-star',
    children: [
      {
        name: 'Login',
        url: '/login',
        icon: 'icon-star'
      },
      {
        name: 'Register',
        url: '/register',
        icon: 'icon-star'
      },
      {
        name: 'Error 404',
        url: '/404',
        icon: 'icon-star'
      },
      {
        name: 'Error 500',
        url: '/500',
        icon: 'icon-star'
      }
    ]
  },*/
  /*{
    name: 'Disabled',
    url: '/dashboard',
    icon: 'icon-ban',
    badge: {
      variant: 'secondary',
      text: 'NEW'
    },
    attributes: { disabled: true },
  }*/

