import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Category } from '../_models/index';

@Injectable()
export class CategoryService {
    constructor(private http: HttpClient) { }

    getByCompanyId(companyId: any) {
        return this.http.get<Category[]>(environment.apiUrl + 'categories?company_id=' + companyId);
    }

    getByCompanyIdAndDescription(companyId: any, description: string) {

        return this.http.get<Category[]>(environment.apiUrl +
            'categories?company_id=' + companyId +
            "&description=" + description);
    }

    async getByFilter(filter: any){
        return await this.http.post<Category[]>(environment.apiUrl + 'categories/filter', filter).toPromise();
    }

    async save(category: Category) {
        return await this.http.post<Category>(environment.apiUrl + 'categories', category).toPromise();
    }

    async update(id: any, category: Category) {
        return this.http.put<Category>(environment.apiUrl + 'categories/' + id, category).toPromise();
    }

    async delete(id: any) {
        return await this.http.delete(environment.apiUrl + "categories/" + id).toPromise();
    }
    async getCountByFilter(filter: any){
      return await this.http.post<any[]>(environment.apiUrl + 'categories/count', filter).toPromise();
  }
}
