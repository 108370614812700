import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UserService } from '../_services';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {
  userLoggedGroup: any;
  canAccess: boolean;
  constructor(
    private authService: AuthService,
    private router: Router,
    private user: UserService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.getUser();

    this.canAccess = this.isRouteInArray(state.url, this.userLoggedGroup?.userGroup?.systemMenus)

    if (this.canAccess || !this.userLoggedGroup?.userGroup) {
      return true;
    } else {
      this.router.navigate(['/denied']);
      return false;
    }
  }

  private async getUser() {
    const userLogged = this.authService.getLoggedUser();
    if (userLogged) {
      try {
        const userData = await this.user.getUserById(userLogged.id);
        this.userLoggedGroup = userData;
      } catch (error) {
        console.error('Erro ao obter dados do usuário:', error);
        this.router.navigate(['/denied']);
      }
    }
  }

  isRouteInArray(currentRoute: string, systemMenus: any[]): boolean {
    return systemMenus?.some(route => route?.systemMenu.url === currentRoute);
  }
}
