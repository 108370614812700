import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/map";
import { environment } from "../../environments/environment";
import { Supplier } from "../_models/index";

@Injectable()
export class SupplierService {
  constructor(private http: HttpClient) {}

  async getByCompanyId(companyId: any) {
    return await this.http
      .get<Supplier[]>(environment.apiUrl + "suppliers?company_id=" + companyId)
      .toPromise();
  }

  async getByFilter(filter: any) {
    return await this.http
      .post<any[]>(environment.apiUrl + "suppliers/filter", filter)
      .toPromise();
  }

  async save(supplier: any) {
    return await this.http
      .post<any[]>(environment.apiUrl + "suppliers", supplier)
      .toPromise().catch(err => {
        throw new Error(err);
      });
  }

  async deactivate(id: any) {
    return await this.http
      .patch<any>(environment.apiUrl + "suppliers/" + id + "/active/false", {})
      .toPromise();
  }

  async activate(id: any) {
    return await this.http
      .patch<any>(environment.apiUrl + "suppliers/" + id + "/active/true", {})
      .toPromise();
  }
  async getCountByFilter(filter: any){
    return await this.http.post<any[]>(environment.apiUrl + 'suppliers/count', filter).toPromise();
}
}
