import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Billing } from '../_models/billing';

@Injectable()
export class BillingService {
    constructor(private http: HttpClient) { }

    getByCompanyId(companyId: any) {
        return this.http.get<Billing[]>(environment.apiUrl + 'billings?company_id=' + companyId).toPromise();
    }

    save(billing: Billing) {
        return this.http.post<Billing>(environment.apiUrl + 'billings', billing).toPromise();
    }
}
