import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { SaleResultReport } from '../_models/index';

@Injectable()
export class ReportService {
    constructor(private http: HttpClient) { }

    async getByFilter(filter: any) {
        return await this.http.post<SaleResultReport>(environment.apiUrl + 'orders/saleresult', filter).toPromise();
    }
}
