import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/first';

import {ModalDirective} from 'ngx-bootstrap/modal';

import { AuthenticationService } from './../../_services/index';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'forgotpassword.component.html',
  styleUrls: ['forgotpassword.component.scss']
})
export class ForgotPasswordComponent implements OnInit{ 

  @ViewChild('loadingModal', {static: true}) public loadingModal: ModalDirective;

  forgotPassForm: FormGroup;
  submitted = false;
  success = false;
  btnSendDisabled = false;
  error = false;

  email: string;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private formBuilder: FormBuilder) { }

  ngOnInit() { 
    
    this.loadingModal;
    this.forgotPassForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  f() { return this.forgotPassForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.success = false;
    this.error = false;
  
    if (this.forgotPassForm.invalid) {
      return;
    }
    this.btnSendDisabled = true;
    this.authenticationService.forgotPassword(this.forgotPassForm.controls.email.value)
      .first()
      .subscribe(
          data => {
            this.success = true;         
          },
          error => {
            this.error = true;
          })
      .add(() => {
        this.submitted = false;
        this.btnSendDisabled = false;
        this.forgotPassForm.reset();  
      });      
  }

  onReset() {
    this.submitted = false;
    this.forgotPassForm.reset();
    this.router.navigate(['login']);
  }
}
