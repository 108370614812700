import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { AlertModule } from 'ngx-bootstrap/alert';
import { UploadFileComponent } from './upload-file.component';
import { ProgressComponent } from './../../views/progress/progress.component';
import { ProgressModule } from '../../views/progress/progress.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

defineLocale('pt-br', ptBrLocale);

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    ProgressModule,
    FormsModule
  ],
  declarations: [ 
    UploadFileComponent
  ],
  exports: [
    UploadFileComponent
  ],
  entryComponents: [
    ProgressComponent
  ]
})
export class UploadFileModule { }
