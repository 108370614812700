<div class="app-body x8-background">
  <main class="main">
    <div class="row row-login">
      <div class="col-md-4 offset-md-1">
        <div class="row" id="div-logo">
          <div class="col-md-2">
            <img src="assets/img/brand/logo.png" width="250" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <div class="card" *ngIf="success" id="card-email-forgotpass">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <img src="./../../../assets/img/sent-email.svg" width="350">
                  </div>
                </div><br />
                <div class="row">
                  <div class="col-md-12">
                    <h4>E-mail enviado com sucesso</h4>
                    <p class="text-explanation">Um e-mail de recuperação de senha foi enviado para sua caixa de entrada. Clique no botão no e-mail para cadastrar uma nova senha e acessar a Plataforma X8!</p>
                  </div>
                </div>
                <div class="row" id="link-login">
                  <div class="col-md-12 box-button-right">
                    <span class="link" (click)="onReset()">Login</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-group" id="card-forgotpass" *ngIf="!success">
              <div class="card">
                <div class="card-body">
                  <alert type="success" *ngIf="success && !error">
                    <strong>Ótimo!</strong> Senha recuperada com sucesso, você receberá uma senha temporária em breve.
                  </alert>
                  <alert type="danger" *ngIf="error && !success">
                    <strong>Ops!</strong> Erro ao gerar uma nova senha. Tente novamente em alguns instantes.
                  </alert>
                  <form #f="ngForm" name="form" [formGroup]="forgotPassForm" (ngSubmit)="onSubmit()" novalidate>
                    <h1>Esqueci minha senha</h1>
                    <p class="text-muted">Informe seu e-mail cadastrado</p>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-envelope-open"></i></span>
                      </div>
                      <input type="text" formControlName="email" class="form-control form-control-lg" placeholder="E-mail" [ngClass]="{ 'is-invalid': submitted && forgotPassForm.controls.email.errors }">                    
                    </div>    
                    <div *ngIf="submitted && forgotPassForm.controls.email.errors" class="invalid-input">
                        <div *ngIf="forgotPassForm.controls.email.errors.required">O campo E-mail é obrigatório</div>
                        <div *ngIf="forgotPassForm.controls.email.errors.email">Informe um e-mail válido</div>
                    </div>                               
                    <div class="row">
                      <div class="col-6">
                        <button class="btn btn-success btn-lg px-3" [disabled]="btnSendDisabled">Enviar
                          <i *ngIf="btnSendDisabled" class="fa fa-circle-o-notch fa-spin"></i>
                        </button>
                      </div>
                      <div class="col-6 box-button-right">
                        <span class="link" (click)="onReset()">Cancelar</span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 box-login-presentation"></div>
    </div>
  </main>
</div>

<div bsModal #loadingModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content" id="loading-content">
      <div class="modal-body">
        <img src="assets/img/loading.gif" />
      </div>
    </div>
  </div>
</div>