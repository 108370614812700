import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OrderTransactionControl } from '../_models/order-transaction-control';

@Injectable()
export class OrderTransactioControlService {
    constructor(private http: HttpClient) { }

    getByCompanyId(companyId: any){
        return this.http.get<OrderTransactionControl[]>(environment.apiUrl + 'ordertransactioncontrol?company_id=' + companyId).toPromise();
    }

    getPendingByCompanyId(companyId: any){
        return this.http.get<OrderTransactionControl[]>(environment.apiUrl + 'ordertransactioncontrol/pending?company_id=' + companyId).toPromise();
    }
}