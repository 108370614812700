import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import 'rxjs/Rx';

@Injectable()
export class ReferralCouponService {
    constructor(private http: HttpClient) { }

    getByTitle(title: string) {
        
        return this.http.get<any>(environment.apiUrl + 'referralcoupon?title=' + title);
    }

    validReferralCoupon() : AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {                    
            if (control.value === null && !control.valueChanges || control.pristine) {
                return of(null);
            } else {
                return this.getByTitle(control.value)            
                .pipe(
                    map(
                        res => {                            
                            return res ? null : {referralCouponValid: true};
                        }                 
                    ),
                    catchError(error => {                        
                        return of({referralCouponValid: true}); 
                    })
                )
            }            
        }
    }
}
