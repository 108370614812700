import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Warning } from '../_models/warning';

@Injectable()
export class WarningsService {
    constructor(private http: HttpClient) { }

    async getWarningByFilter(bodyWarning: any) {
        return await this.http.post<any>(environment.apiUrl + 'warnings/filter', bodyWarning).toPromise();
    }

    async updateWarning(id: number, valueStatus: any) {
      return await this.http.patch(environment.apiUrl + `warnings/${id}`, valueStatus).toPromise();
    }
}
