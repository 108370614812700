import { ProdutcVariationTypeValue } from "./product-variation-type-value";

export class ProductVariationType {

    id: any;
    key: string;
    description: string;
    level: any;
    hasImage: boolean;
    values: ProdutcVariationTypeValue[];
}
