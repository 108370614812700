<div class="main-container" *ngFor="let warning of dataWarnings" >
  <div class="container-alert" *ngIf="warning.status">
    <div class="row">
      <div class="col-md-12">
        <alert class="alert-danger-form" type="warning">
          <div class="title-alert">
            <div class="container-text-warning">
              <p class="text-warning">
                <strong>{{ warning.title }}</strong>
                <span>{{ warning.description }}</span>
                <span class="date-label">{{ warning.createdAt | date: "dd/MM/yyyy"}}</span>
              </p>
            </div>
            <button (click)="closeAlertParent(warning.id)">
              <strong><i class="fa fa-remove"></i></strong>
            </button>
          </div>
        </alert>
      </div>
    </div>
  </div>
</div>
