import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { CompanyPrinter } from '../_models/company-printer';

@Injectable()
export class PrinterService {
    constructor(private http: HttpClient) { }

    async create(companyPrinter: any) {
      return await this.http.post(environment.apiUrl + `companyprinters`, companyPrinter).toPromise();
    }

    async update(companyPrinter: CompanyPrinter) {
      return await this.http.put(environment.apiUrl + `companyprinters/${companyPrinter.id}`, companyPrinter).toPromise();
    }

    async getByCompanyId(companyId: any) {
      return await this.http.get<CompanyPrinter>(environment.apiUrl + `companyprinters?company_id=${companyId}`).toPromise();
    }

    async getPrinters(apiKey: string) {
        return await this.http.get<any[]>(environment.apiUrl + `companyprinters/printers?api_key=${apiKey}`).toPromise();
    }

    async uptatePreferences(id: number, valueCheck: any) {
      return await this.http.patch(environment.apiUrl + `companyprinters/${id}`, valueCheck).toPromise();
    }

    async noopPrinter(apiKey: string) {
      return await this.http.get(environment.apiUrl + `companyprinters/noop?api_key=${apiKey}`).toPromise();
  }
}
