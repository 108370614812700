<div class="box-2fa-presentation">
    <main class="main">
      <div class="row justify-content-center row-2fa">
        <div class="col-md-6">
          <div class="card" id="card-2fa">
            <div class="card-body">
              <h1>Autenticação em 2 Fatores</h1>
              <p class="text-muted text-explanation">Insira o código de 6 dígitos</p>
              <form [formGroup]="authForm" (ngSubmit)="onSubmit()">
                <div class="input-group mb-3 input-2fa" id="card-input-2fa">
                    <input type="text" id="code1" formControlName="code1" class="code-input" maxlength="1" pattern="\d*" (input)="moveFocus($event, 'code2')">
                    <input type="text" id="code2" formControlName="code2" class="code-input" maxlength="1" pattern="\d*" (input)="moveFocus($event, 'code3')">
                    <input type="text" id="code3" formControlName="code3" class="code-input" maxlength="1" pattern="\d*" (input)="moveFocus($event, 'code4')">
                    <input type="text" id="code4" formControlName="code4" class="code-input" maxlength="1" pattern="\d*" (input)="moveFocus($event, 'code5')">
                    <input type="text" id="code5" formControlName="code5" class="code-input" maxlength="1" pattern="\d*" (input)="moveFocus($event, 'code6')">
                    <input type="text" id="code6" formControlName="code6" class="code-input" maxlength="1" pattern="\d*" (input)="moveFocus($event, null)">
                </div>
                <div *ngIf="submitted && authForm.invalid" class="invalid-feedback">
                    <div *ngIf="authForm.errors?.required">O código é obrigatório</div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button type="submit" class="btn btn-success btn-lg btn-block" [disabled]="loading">
                      Verificar
                      <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin"></i>
                    </button>
                  </div>
                </div>
                <div id="link-back" class="text-center">
                  <a class="link" (click)="goBack()">Voltar</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  