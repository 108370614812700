import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Supplier } from '../_models/index';
import { Purchase } from '../_models/purchase';

@Injectable()
export class PurchaseService {
    constructor(private http: HttpClient) { }

    async save(purchase: any){
        return await this.http.post<any[]>(environment.apiUrl + 'purchases', purchase).toPromise();
    }

    async delete(id: any) {
        return await this.http.delete<any>(environment.apiUrl + "purchases/" + id).toPromise();
    }

    async reopen(purchase: any) {
        return await this.http.patch<any>(environment.apiUrl + "purchases/reopen", purchase).toPromise();
    }

    async getByCompanyId(companyId: any) {
        return await this.http.get<Supplier[]>(environment.apiUrl + 'suppliers?company_id=' + companyId).toPromise();
    }

    async getById(id: any) {
        return await this.http.get<Purchase>(environment.apiUrl + 'purchases/' + id).toPromise();
    }

    import(file: File) {
        const formData: FormData = new FormData();

        formData.append('file', file);
        //formData.append('purchase', JSON.stringify(purchase));

        return this.http.post<any>(`${environment.apiUrl}purchases/upload`, formData);
    }
    importXml(file: File, companyId: number) {
        const formData: FormData = new FormData();

        formData.append('file', file);
        return this.http.post<any>(`${environment.apiUrl}purchases/importation?company_id=${companyId}`, formData);
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'purchases/filter', filter).toPromise();
    }

    async getSuggestionsByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'purchases/suggestions', filter).toPromise();
    }
}
