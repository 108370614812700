import { MarketplaceAccount } from '.';
import { AnnouncementPicture } from './announcement-picture';
import { AnnouncementProduct } from './announcement-product';

export class Announcement {

    id: any;
    marketplaceReference: any;
    sku: any;
    ncm: string;
    title: string;
    variationDescription: string;
    price: any;
    shippingCost: any;
    fee: any;
    cost: any;
    thumbnail: any;
    warranty: string;
    status: any;
    permalink: string;
    pictures: AnnouncementPicture[];
    products: AnnouncementProduct[];
    marketplaceSource: MarketplaceAccount;
    company: any;
    visits: any;
    health: any;
    sales: any;
    stock: any;
    listingTypeId: string;
    freeShipping: boolean;
    rootCategory: string;
    announcement: Announcement;
    announcements: Announcement[];
    description?: string;
    category?: string;
    categoryId?: string;
    ean?: string;
}