import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AsyncValidatorFn, FormControl } from '@angular/forms';
import { MustMatch } from '../../_helpers/must-match.validator';
import { AuthenticationService, CompanyService, ReferralCouponService } from '../../_services/index';
import { Company, User } from '../../_models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  loading: BsModalRef;
  registerForm: FormGroup;
  submitted = false;
  btnSaveDisabled = false;
  success = false;
  company: Company;
  plan: string;
  showPassword = false;
  showConfirmPassword = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private service: CompanyService,
    private referralCouponService: ReferralCouponService,
    private bsModalService: BsModalService,
    private authService: AuthenticationService,
    private toastr: ToastrService) { }

  async register() {
    
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.btnSaveDisabled = true;
    
    if (this.registerForm.controls.pessoa.value === 'F') {
      if (!this.validaCPF(this.registerForm.controls.cpf.value)) {
        this.toastr.error("O CPF informado é inválido");
        return;
      }
    } else if (this.registerForm.controls.pessoa.value === 'J') {
      if (!this.validaCNPJ(this.registerForm.controls.cnpj.value)) {
        this.toastr.error("O CNPJ informado é inválido");
        return;
      }
    }

    this.company = {
      id: 0,
      cpfCnpj: this.registerForm.controls.pessoa.value == 'F' ? this.registerForm.controls.cpf.value : this.registerForm.controls.cnpj.value,
      companyName: this.registerForm.controls.company.value,
      email: this.registerForm.controls.email.value,
      phone: this.registerForm.controls.phone.value,
      companyType: this.registerForm.controls.pessoa.value == 'F' ? 0 : 1,
      user: new User(),
      plan: this.plan,
      defaulting: false,
      referralCouponTitle: this.registerForm.controls.referralCoupon.value,
      createdAt: null,
      testEndsAt: null
    }
    this.company.user = {
      email: this.registerForm.controls.email.value,
      login: this.registerForm.controls.email.value,
      password: this.registerForm.controls.password.value,
      name: this.registerForm.controls.company.value//this.registerForm.controls.accountable.value
    }
    await this.service.save(this.company)
    .then(
      data => {
        this.toastr.success("Cadastro realizado com sucesso!");
        this.submitted = false;
        this.btnSaveDisabled = false;
        this.success = true;
        //await this.timeout(3000);
        //this.router.navigate(['/login']);
      },
      (err) => {
        this.toastr.error("Não foi possível realizar o cadastro");
        this.submitted = false;
        this.btnSaveDisabled = false;
      }
    );    
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  login() {
    this.router.navigate(['/login']);
  }

  selectPerson(person: string) {
    
    if (person === 'F') {
      this.registerForm.controls.cpf.setValue('');
      this.registerForm.controls.cnpj.setValue('00000000000000');
    } else {
      this.registerForm.controls.cpf.setValue('00000000000');
      this.registerForm.controls.cnpj.setValue('');
    }
  }
    
  referralCouponValidator(formGroup: FormGroup) {
    
    if (formGroup.value.referralCoupon !== "") {
      if (this.referralCouponService !== undefined) {
        this.referralCouponService.getByTitle(formGroup.controls.referralCoupon.value)
        .pipe()
        .map(data => {
          
          return data ? {referralCouponValid: true} : false;
        });        
      }
    } 
    return null;
  }

  cpfConditionallyRequiredValidator(formGroup: FormGroup) {
    if (formGroup.value.pessoa === 'F') {
      return Validators.required(formGroup.get('cpf')) ? {
        cpfRequired: true,
      } : null;
    } 
    return null;
  }

  cnpjConditionallyRequiredValidator(formGroup: FormGroup) {
    if (formGroup.value.pessoa === 'J') {
      return Validators.required(formGroup.get('cnpj')) ? {
        cnpjRequired: true,
      } : null;
    } 
    return null;
  }

  cpfValidator(formGroup: FormGroup) {
    return this.validaCPF(formGroup.value.cpf) ? true : null;
  }

  cnpjValidator(formGroup: FormGroup) {
    return this.validaCNPJ(formGroup.value.cnpj) ? true : null;
  }

  validaCNPJ(cnpj) {
    // Verifica se a variável cnpj é igua a "undefined", exibindo uma msg de erro
    if (cnpj === undefined) {
      return false;
    }

    // Esta função retira os caracteres . / - da string do cnpj, deixando apenas os números 
    var strCNPJ = cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

    // Testa as sequencias que possuem todos os dígitos iguais e se o cnpj não tem 14 dígitos, retonando falso e exibindo uma msg de erro
    if (strCNPJ === '00000000000000' || strCNPJ === '11111111111111' || strCNPJ === '22222222222222' || strCNPJ === '33333333333333' ||
      strCNPJ === '44444444444444' || strCNPJ === '55555555555555' || strCNPJ === '66666666666666' || strCNPJ === '77777777777777' ||
      strCNPJ === '88888888888888' || strCNPJ === '99999999999999' || strCNPJ.length !== 14) {
     
      return false;
    }

    // A variável numeros pega o bloco com os números sem o DV, a variavel digitos pega apenas os dois ultimos numeros (Digito Verificador).
    var tamanho = strCNPJ.length - 2;
    var numeros = strCNPJ.substring(0, tamanho);
    var digitos = strCNPJ.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    // Os quatro blocos seguintes de funções irá reaizar a validação do CNPJ propriamente dito, conferindo se o DV bate. Caso alguma das funções não consiga verificar
    // o DV corretamente, mostrará uma mensagem de erro ao usuário e retornará falso, para que o usário posso digitar novamente um número 
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0)) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let k = tamanho; k >= 1; k--) {
      soma += numeros.charAt(tamanho - k) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1)) {
      return false;
    }

    return true;
  }

  validaCPF(cpf) {
    var Soma = 0;
    // Verifica se a variável cpf é igual a "undefined", exibindo uma msg de erro
    if (cpf === undefined) {
      return false;
    }

    // Esta função retira os caracteres . e - da string do cpf, deixando apenas os números 
    var strCPF = cpf.replace('.', '').replace('.', '').replace('-', '');
    // Testa as sequencias que possuem todos os dígitos iguais e, se o cpf não tem 11 dígitos, retorna falso e exibe uma msg de erro
    if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' || 
    strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' || strCPF === '88888888888' || 
    strCPF === '99999999999' || strCPF.length !== 11) {
      return false;
    }

    // Os seis blocos seguintes de funções vão realizar a validação do CPF propriamente dito, conferindo se o DV bate. Caso alguma das funções não consiga verificar
    // o DV corretamente, mostrará uma mensagem de erro ao usuário e retornará falso, para que o usário posso digitar novamente um número para ser testado

    //Multiplica cada digito por numeros de 1 a 9, soma-os e multiplica-os por 10. Depois, divide o resultado encontrado por 11 para encontrar o resto
    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    }

    var Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(9, 10))) {
      return false;
    }

    Soma = 0;
    for (let k = 1; k <= 10; k++) {
      Soma = Soma + parseInt(strCPF.substring(k - 1, k)) * (12 - k)
    }

    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(10, 11))) {
      return false;
    }

    return true;
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  ngOnInit() {
    this.authService.logout();
    this.registerForm = this.formBuilder.group({      
      company: ['', [Validators.maxLength(45), Validators.required]],
      pessoa: ['J', [Validators.required]],
      referralCoupon: [null, [Validators.maxLength(20)], this.referralCouponService.validReferralCoupon()],
      cpf: ['', []],
      cnpj: ['', []],
      email: ['', [Validators.maxLength(150), Validators.required]],
      phone: ['', [Validators.maxLength(15), Validators.required]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')
        ]
      ],
      confirmPassword: ['', Validators.required],
    }, {
      validators: [MustMatch('password', 'confirmPassword'),
                  this.cpfConditionallyRequiredValidator,
                  this.cnpjConditionallyRequiredValidator]
    }); 

    this.activatedRoute.queryParams.subscribe(params => {
      const plan = params['plan'];
      if (plan !== "esmeralda" &&
          plan !== "espinela" &&
          plan !== "rubi" &&
          plan !== "safira" &&
          plan !== "ametista" &&
          plan !== "diamante") {
        this.toastr.error("Desculpe! Plano inexistente.");
      }
      this.plan = plan;
    });
  }
}
