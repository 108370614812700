import { Announcement } from './announcement';
import { AnnouncementProductId } from './announcement-product-id';
import { Product } from './product';

export class AnnouncementProduct {

    id: AnnouncementProductId;
    announcement: Announcement;
    product: Product;
    quantity: any;
}