import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BankDraft } from '../_models/bank-draft';
import { BankDraftFilterDTO } from '../_models/bank-draft-filters';

@Injectable()
export class BankDraftService {
  constructor(private http: HttpClient) { }

  getByFilter(filter: BankDraftFilterDTO) {
    return this.http.post<BankDraft[]>(environment.apiUrl + 'bankdrafts/filter', filter);
  }

  async getById(id: any) {
    return await this.http.get<BankDraft[]>(environment.apiUrl + 'bankdrafts/' + id).toPromise();
  }

  save(bankDraft: any) {
    return this.http.post<BankDraft>(environment.apiUrl + 'bankdrafts', bankDraft).toPromise();
  }

  update(bankDraft: any) {
    return this.http.put<any[]>(environment.apiUrl + 'bankdrafts/' + bankDraft.id, bankDraft).toPromise();
}

  deactivate(id: any) {
    return this.http.patch<any>(environment.apiUrl + "bankdrafts/" + id + "/active/false", {});
  }

  activate(id: any) {
    return this.http.patch<any>(environment.apiUrl + "bankdrafts/" + id + "/active/true", {});
  }

  getCountByFilter(filter: any) {
    return this.http.post<number>(environment.apiUrl + 'bankdrafts/count', filter);
  }

  async getOrderResultByPeriod(from: string, to: string) {
    return await this.http.get<any[]>(environment.apiUrl + 
        'bankdrafts/filter' + '&from=' + from + '&to=' + to).toPromise();
  }

  getCompanyId() {
    const user = localStorage.getItem("user");
    if(user){
      const parsed = JSON.parse(user);
      return parsed.company.id;
    }
    return null;
  }

  async delete(id: any) {
    return await this.http.delete<any>(environment.apiUrl + "bankdrafts/" + id).toPromise();
  }  
}
