<div class="app-body x8-background">
  <main class="main">
    <div class="row">
      <div class="col-md-4 offset-md-1">
        <div class="row" id="div-logo">
          <div class="col-md-4">
            <img src="assets/img/brand/logo.png" width="250" />
          </div>
        </div>
        <div class="row row-register-form">
          <div class="col-md-10">
            <div class="card" *ngIf="success" id="card-register-success">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <img src="./../../../assets/img/sent-email.svg" width="350">
                  </div>
                </div><br />
                <div class="row">
                  <div class="col-md-12">
                    <h4>Cadastro realizado com sucesso</h4>
                    <p class="text-explanation">Um e-mail de confirmação foi enviado para sua caixa de entrada. Por favor verifique para acessar a Plataforma X8!</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 box-button-right">
                    <span class="link" (click)="login()">Login</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card" *ngIf="!success">
              <div class="card-body">
                <form name="form" (ngSubmit)="register()" [formGroup]="registerForm" novalidate>
                  <h1>Cadastre-se</h1>
                  <p class="text-muted">Crie agora sua conta</p>
                  <alert type="success" *ngIf="success">
                    Conta criada com sucesso!
                  </alert>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group row">
                        <label class="col-md-2 col-form-label" for="inline-radios">Sou: </label>
                        <div class="col-md-10 col-form-label">
                          <div class="form-check form-check-inline mr-1" id="inline-radios">
                            <input class="form-check-input" type="radio" formControlName="pessoa" name="pessoa" id="inlineRadio1" value="F" (change)="selectPerson('F')">
                            <label class="form-check-label" for="inlineRadio1">Pessoa Física</label>
                          </div>
                          <div class="form-check form-check-inline mr-1">
                            <input class="form-check-input" type="radio" formControlName="pessoa" name="pessoa" id="inlineRadio2" value="J" (change)="selectPerson('J')">
                            <label class="form-check-label" for="inlineRadio2">Pessoa Jurídica</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-home"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-lg" placeholder="Nome ou Empresa" autocomplete="empresa" 
                          formControlName="company" name="company" [ngClass]="{ 'is-invalid': submitted && registerForm.controls.company.errors }">
                        <div *ngIf="submitted && registerForm.controls.company.errors" class="invalid-input">
                          <div *ngIf="registerForm.controls.company.errors.maxlength">O Nome/Empresa deve conter no máximo 45 caractéres</div>
                          <div *ngIf="registerForm.controls.company.errors.required">O campo Nome/Empresa é obrigatório</div>
                        </div>
                      </div>
                      <div class="input-group mb-3" *ngIf="registerForm.controls.pessoa.value == 'F'" >
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fa fa-id-card-o"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-lg" placeholder="000.000.000-00"
                          formControlName="cpf" name="cpf" 
                          [ngClass]="{ 'is-invalid': submitted && registerForm.errors }"
                          mask="000.000.000-00">                  
                        <div *ngIf="submitted && registerForm.errors" class="invalid-input">
                          <div *ngIf="registerForm.errors.cpfRequired">O campo CPF é obrigatório</div>                    
                        </div>
                      </div>
                      <div class="input-group mb-3" *ngIf="registerForm.controls.pessoa.value == 'J'">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fa fa-id-card-o"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-lg" placeholder="00.000.000/0000-00" 
                          formControlName="cnpj" name="cnpj" 
                          [ngClass]="{ 'is-invalid': submitted && registerForm.errors }"
                          mask="00.000.000/0000-00">
                        <div *ngIf="submitted && registerForm.errors" class="invalid-input">
                          <div *ngIf="registerForm.errors.cnpjRequired">O campo CNPJ é obrigatório</div>                
                        </div>
                      </div>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">@</span>
                        </div>
                        <input type="text" class="form-control form-control-lg" placeholder="Email" autocomplete="email" 
                          formControlName="email" name="email" [ngClass]="{ 'is-invalid': submitted && registerForm.controls.email.errors }">
                        <div *ngIf="submitted && registerForm.controls.email.errors" class="invalid-input">
                          <div *ngIf="registerForm.controls.email.errors.required">O campo E-mail é obrigatório</div>
                          <div *ngIf="registerForm.controls.email.errors.maxlength">O número do pedido deve conter no máximo 150 caractéres</div>                    
                        </div>
                      </div>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-phone"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-lg" placeholder="(00) 00000-0000" autocomplete="Celular" 
                          formControlName="phone" name="phone" mask="(00) 00000-0000"
                          [ngClass]="{ 'is-invalid': submitted && registerForm.controls.phone.errors }">
                        <div *ngIf="submitted && registerForm.controls.phone.errors" class="invalid-input">
                          <div *ngIf="registerForm.controls.phone.errors.required">O campo Celular é obrigatório</div>
                          <div *ngIf="registerForm.controls.phone.errors.maxlength">O campo Celular deve contér no máximo 15 caractéres</div>
                        </div>
                      </div>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-lock"></i></span>
                        </div>
                        <input
                          [type]="showPassword ? 'text' : 'password'"
                          class="form-control form-control-lg"
                          placeholder="Senha"
                          autocomplete="password"
                          formControlName="password"
                          name="password"
                          [ngClass]="{ 'is-invalid': submitted && registerForm.controls.password.errors }"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" (click)="togglePasswordVisibility()">
                            <i [class]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                          </span>
                        </div>
                        <div *ngIf="submitted && registerForm.controls.password.errors" class="invalid-input">
                          <div *ngIf="registerForm.controls.password.errors.required">
                            O campo Senha é obrigatório
                          </div>
                          <div *ngIf="registerForm.controls.password.errors.minlength">
                            A senha deve conter no mínimo 8 caracteres
                          </div>
                          <div *ngIf="registerForm.controls.password.errors.pattern">
                            A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial
                          </div>
                        </div>
                      </div>
                      <div class="input-group mb-4">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-check"></i></span>
                        </div>
                        <input
                          [type]="showConfirmPassword ? 'text' : 'password'"
                          class="form-control form-control-lg"
                          placeholder="Confirme a senha"
                          autocomplete="confirmPassword"
                          formControlName="confirmPassword"
                          name="confirmPassword"
                          [ngClass]="{ 'is-invalid': submitted && registerForm.controls.confirmPassword.errors }"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" (click)="toggleConfirmPasswordVisibility()">
                            <i [class]="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                          </span>
                        </div>
                        <div *ngIf="submitted && registerForm.controls.confirmPassword.errors" class="invalid-input">
                          <div *ngIf="registerForm.controls.confirmPassword.errors.required">
                            O campo Confirme a senha é obrigatório
                          </div>
                          <div *ngIf="registerForm.controls.confirmPassword.errors.mustMatch">
                            Deve ser igual ao campo Nova senha
                          </div>
                        </div>
                      </div>
                      <div class="input-group mb-4">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-star"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-lg" placeholder="Cupom de Indicação" 
                          formControlName="referralCoupon" name="referralCoupon" [ngClass]="{ 'is-invalid': submitted && registerForm.controls.referralCoupon?.errors }">
                        <div *ngIf="submitted && registerForm.controls.referralCoupon?.errors" class="invalid-input">
                          <div *ngIf="registerForm.controls.referralCoupon.errors?.maxlength">O campo Cupom de Indicação deve contér no máximo 20 caractéres</div>
                          <div *ngIf="registerForm.controls.referralCoupon.errors?.referralCouponValid">Cupom de Indicação inválido</div>
                        </div>
                      </div>
                    </div>
                  </div>                
                  <button type="submit" [disabled]="btnSaveDisabled" class="btn btn-block btn-success btn-lg">
                    Criar conta <i [ngClass]="{ 'fa fa-spinner fa-spin': btnSaveDisabled, 'icon-rocket': !btnSaveDisabled }"></i></button>
                </form><br />
                <div class="row">
                  <div class="col-12 box-button-right">
                    <span class="link" (click)="login()">Login</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 box-login-presentation"></div>
    </div>
  </main>
</div>
