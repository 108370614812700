import { Component, OnInit, Input } from '@angular/core';
import { UploadFileService, MarketplaceAccountService } from './../../_services';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MarketplaceAccount } from '../../_models';
import { AuthService } from '../../_guards';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoadingComponent } from '../../views/loading/loading.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {

  @Input() text: string;
  @Input() resourceUrl: string;
  @Input() acceptFiles: string[];

  files: any[] = [];
  selectedFiles: FileList;
  currentFile: File;
  progress = 0;
  message = '';
  sentSuccessfully: boolean;
  hasError: boolean;
  messageError = ''; 
  submitted = false; 
  loadedFile = false;

  private importExtractForm: FormGroup;
  private loading: BsModalRef;
  public marketplaceAccount: MarketplaceAccount;

  fileInfos: Observable<any>;

  //TODO - Deixar customizavel
  private marketplaceAccounts: MarketplaceAccount[];

  constructor(private uploadService: UploadFileService,
    private toastr: ToastrService,
    private marketplaceAccountService: MarketplaceAccountService,
    private authService: AuthService,
    private bsModalService: BsModalService,
    private formBuilder: FormBuilder) {

    this.marketplaceAccount = new MarketplaceAccount();

    const companyId = authService.getCompanyId();
    this.marketplaceAccountService.getByCompanyId(companyId)
    .then (
      data => {
        this.marketplaceAccounts = data;
      }
    )
  }

  ngOnInit() {
    //this.fileInfos = this.uploadService.getFiles();
    this.hasError = false;
    this.resourceUrl = 'financialmovements/upload';
    //this.acceptFiles = ['application/vnd.ms-excel'];

    this.importExtractForm = this.formBuilder.group({
      accounts : [null, [Validators.required]]
    });
  }

  selectFile(event) {      
    if (this.validateFile(event)) { return; }

    this.prepareFilesList(event);
    this.selectedFiles = event;
  }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  reload() {
    this.files = [];
    this.loadedFile = false;        
    this.currentFile = undefined;
    this.loading.hide();
  }

  upload() {
    this.submitted = true;
    if (this.importExtractForm.invalid) {
      this.currentFile = undefined;
      this.files[0].progress = 0;
      return;
    }
    this.loading = this.bsModalService.show(LoadingComponent, {ignoreBackdropClick: true});
    this.currentFile = this.selectedFiles.item(0);
    this.uploadService.upload(this.resourceUrl, this.currentFile, this.importExtractForm.controls.accounts.value.platformId).subscribe(
      event => {
                
        this.reload();
        this.toastr.success("Importação realizada com sucesso!");      
      },
      err => {
        //this.progress = 0;
        this.reload();
        this.toastr.error(err.error.message);
      }
    );
      
    this.marketplaceAccount = new MarketplaceAccount();
    this.submitted = false;
  }

  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.loadedFile = true;
            //this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 10;
          }
        }, 200);
      }
    }, 1000);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item['progress'] = 0;
      this.files.push(item);
    }
    this.uploadFilesSimulator(0);
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  validateFile(event) {    
    if (event[0] == undefined) {
      this.hasError = true;
      return this.hasError;
    }
    if (!this.acceptFiles.includes(event[0].type)) {
        this.messageError = 'Apenas arquivos com as extensões ' + this.acceptFiles.toString() + ' são aceitos!';
        this.hasError = true;
    }
    return this.hasError;
  }

  init(resourceUrl, acceptFiles) {
      
    this.resourceUrl = resourceUrl;
    this.acceptFiles = acceptFiles;
  }
}