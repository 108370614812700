
import { Company } from './company';
import { FinancialMovement } from './financial-movement';

export class Pendency {

    id: any;
    resolutionDate: any;
    financialMovement: FinancialMovement;
    bankAccountId: any;
    company: Company;
}