import { Company } from './company';

export class Billing {

    id: any;
    date: string;
    company: Company;
    description: string;
    value: any;
    status: any;
}
