import { Company } from "./company";
import { FinancialMovementTypeGroup } from "./financial-movement-type-group";

export class FinancialMovementType {

    id: any;
    description: string;
    creditDebit: boolean;
    active: boolean;
    marketplaceKey: any;
    key: string;
    isSysyem: boolean;
    resolveBatch: boolean;
    group: FinancialMovementTypeGroup
    company: Company;
    createdAt: any;
}