export class BankAccount {

    id: any;
    company: any;
    numberBank: any;
    accountBank: any;
    agencyBank: any;
    digitBank: any;
    description: string;
    active: boolean;
    physicalBalance: any;
    physicalBalanceBlocked: any;
    initialBalance: any;
}