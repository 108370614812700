import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Supplier } from '../_models/index';
import { FinancialMovement } from '../_models/financial-movement';

@Injectable()
export class FinancialBillingService {
    constructor(private http: HttpClient) { }

    async save(financialbilling: any){
        return await this.http.post<any[]>(environment.apiUrl + 'financialbillings', financialbilling).toPromise();
    }

    async delete(id: any) {
        return await this.http.delete<any>(environment.apiUrl + "financialbillings/" + id).toPromise();
    }

    async getByCompanyId(companyId: any) {
        return await this.http.get<Supplier[]>(environment.apiUrl + 'suppliers?company_id=' + companyId).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'financialbillings/filter', filter).toPromise();
    }
}
