export class OrderItem{

    id: any;
    idOrigem: any;
    marketplacePedido: any;
    marketplaceReference: string;
    permalink: string;
    sku: any;
    ean: any;
    quantity: any;
    saleFee: any;
    unitSaleValue: any;
    unitAverageCoastValue: any;
    unitCostValue: any;
    unitItemDiscountValue: any;
    description: any;
    variation: string;
    listingType: string;
    image: string;
    urlImage: string;
}
