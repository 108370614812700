export class Customer {

    id: any;
    cpfCnpj: string;
    email: string;
    nameSocialReason: string;
    tradingName: string;
    person: string;
    phone: string;
    active: boolean;
    address: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    zipCode: string;
    nickname: string;
}
