import { Message } from './message';
import { Devolution } from './devolution';
import { FinancialMovement } from './financial-movement';
import { Order } from './order';
import { MarketplaceAccount } from '.';
import { Invoice } from './invoice';

export class OrderPackage {

    id: any;
    packId: string;
    company: any;
    marketplaceSource: MarketplaceAccount;
    orders: Order[];
    invoice: Invoice;
    devolutions: Devolution[];
    messages: Message[];
    date: string;
    total: any;
    saleFinancialStatus: any;
    costYield: any;
    orderYield: any;
    transactions: FinancialMovement[];
    finalTotal: any;
    commission: any;
    freight: any;
    averagePriceValue: any;
    differenceValue: any;
    buyerReference: string;
    conversationStatus: any;
    prepared: any;
    checkoutedAt: any;
    getCostYieldPercent: any;
    orderYieldPercent: any;
}