import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { DigitalCertificate } from '../_models/digital-certificate';

@Injectable()
export class DigitalCertificateService {
    constructor(private http: HttpClient) { }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'digitalcertificates/filter', filter).toPromise();
    }

    async getByCompanyId(companyId) {
        return await this.http.get<DigitalCertificate[]>(environment.apiUrl + 'digitalcertificates?company_id=' + companyId).toPromise();
    }
}
