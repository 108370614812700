import { BankAccount } from "./bank-account";
import { Company } from "./company";
import { DigitalCertificate } from "./digital-certificate";

export class MarketplaceAccount {

    id?: any;
    description?: string;
    accountId?: string;
    platform?: string;
    platformId?: any;
    nickname?: string;
    firstNameAccount?: string;
    lastNameAccount?: string;
    company?: Company;
    bankAccount?: BankAccount;
    digitalCertificate?: DigitalCertificate;
    firstAccess?: boolean;
    initialLoad?: boolean;
    active?: boolean;
    accessToken?: string;
    refreshToken?: string;
    automaticIssuanceInvoice?: boolean;
    includeFreightInvoice?: boolean;
    nfePercentage?: any;
    authenticationCode?: string;
    integration?: boolean;
}