import { Claim } from './claim';
import { Customer } from './customer';
import { Devolution } from './devolution';
import { MarketplaceAccount } from './marketplace-account';
import { OrderItem } from './order-item';
import { OrderPayment } from './order-payment';
import { OrderShipping } from './order-shipping';

export class Order{

    id: any;
    idOrigem: string;
    marketplacePedido: string;
    packId: string;
    company: any;
    date: string;
    price: any;
    coupon: any;
    averagePriceValue: any;
    shipping: OrderShipping;
    shippingCost: any;
    payments: Array<OrderPayment>;
    customer: Customer;
    status: string;
    items: OrderItem[];
    devolution: Devolution;
    claim: Claim;
    marketplaceSource: MarketplaceAccount;
    costChanged: boolean;
}
