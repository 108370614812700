import { Company } from "./company";

export class Category {

    id: any;
    description: string;
    rootDescription: string;
    category: Category;
    categories: Array<Category>;
    company: Company;
}