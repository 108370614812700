import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Pendency } from '../_models/index';

@Injectable()
export class PendencyService {
    constructor(private http: HttpClient) { }

    async getByCompanyId(companyId: any, pageNo: any, pageSize: any, sortBy: string) {
        return await this.http.get<Pendency[]>(environment.apiUrl + 'pendencies?company_id=' + 
            companyId + '&page_no=' + pageNo + "&page_size=" + pageSize + "&sort_by=" + sortBy).toPromise();
    }

    getTotalByCompany(companyId: any) {
        return this.http.get<Pendency>(environment.apiUrl + 'pendencies/count/' + companyId);
    }

    resolvePendency(pendency: Pendency) {
        return this.http.put<Pendency>(environment.apiUrl + 'pendencies', pendency);
    }

    resolveAllPendency(originType: any, targetType: any, companyId: any) {
        return this.http.put<Pendency>(environment.apiUrl + 'pendencies/resolution?origin_type=' + 
            originType + "&target_type=" + targetType + "&company_id=" + companyId, null);
    }
}
