import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BankDraftFilterDTO } from '../_models/bank-draft-filters';

@Injectable()
export class BankDraftHolderService {
  constructor(private http: HttpClient) { }

  getByFilter(filter: any) {
    return this.http.post<any[]>(environment.apiUrl + 'bankdraftholders/filter', filter);
  }

  async getById(id: any) {
    return await this.http.get<any[]>(environment.apiUrl + 'bankdraftholders/' + id).toPromise();
  }

  save(bankDraftHolder: any) {
    return this.http.post<any>(environment.apiUrl + 'bankdraftholders', bankDraftHolder).toPromise();
  }

  update(bankDraftHolder: any) {
    return this.http.put<any[]>(environment.apiUrl + 'bankdraftholders/' + bankDraftHolder.id, bankDraftHolder).toPromise();
  }

  deactivate(id: any) {
    return this.http.patch<any>(environment.apiUrl + "bankdraftholders/" + id, {"active": false}).toPromise();
  }

  activate(id: any) {
    return this.http.patch<any>(environment.apiUrl + "bankdraftholders/" + id, {"active": true}).toPromise();
  }

  getCountByFilter(filter: any) {
    return this.http.post<number>(environment.apiUrl + 'bankdraftholders/count', filter);
  }

  async getOrderResultByPeriod(from: string, to: string) {
    return await this.http.get<any[]>(environment.apiUrl + 
        'bankdraftholders/filter' + '&from=' + from + '&to=' + to).toPromise();
  }

  getCompanyId() {
    const user = localStorage.getItem("user");
    if(user){
      const parsed = JSON.parse(user);
      return parsed.company.id;
    }
    return null;
  }
}
