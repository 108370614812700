import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class GroupFinancialMovementService {
    constructor(private http: HttpClient) { }

    async getByFilter(filter: any){
        return await this.http.post<any>(environment.apiUrl + 'groupfinancialmovements/filter', filter).toPromise();
    }
}