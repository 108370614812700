import { User } from './user';

export class Company {

    id: any;
    companyName: string;
    email: string;
    phone: string;
    cpfCnpj: string;
    companyType: any;
    user: User;
    plan: string;
    referralCouponTitle: string;
    defaulting: boolean;
    createdAt: string;
    testEndsAt: string;
}