import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'modal-loading',
    templateUrl: 'loading.component.html'
})
export class LoadingComponent implements OnInit{

    constructor(public bsModalRef: BsModalRef){}

    ngOnInit(){}
}
