import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { StockMovement } from '../_models/index';

@Injectable()
export class StockMovementService {
    constructor(private http: HttpClient) { }

    async save(stockMovement: any){
        return await this.http.post<StockMovement[]>(environment.apiUrl + 'stockmovements', stockMovement).toPromise();
    }

    async delete(id: any) {
        return await this.http.delete<any>(environment.apiUrl + "stockmovements/" + id, {}).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'stockmovements/filter', filter).toPromise();
    }
}
