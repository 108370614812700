import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Customer } from '../_models/index';

@Injectable()
export class CustomerService {
    constructor(private http: HttpClient) { }

    getByName(name: string, companyId: any) {
        return this.http.get<Customer[]>(environment.apiUrl + 'customers?name=' + name + "&company_id=" + companyId);
    }
    getByNamePromise(name: string, companyId: any) {
        return this.http.get<Customer[]>(environment.apiUrl + 'customers?name=' + name + "&company_id=" + companyId).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'customers/filter', filter).toPromise();
    }

    async save(customer: any){
        return await this.http.post<any[]>(environment.apiUrl + 'customers', customer).toPromise();
    }

    async deactivate(id: any) {
        return await this.http.patch<any>(environment.apiUrl + "customers/" + id + "/active/false", {}).toPromise();
    }

    async activate(id: any) {
        return await this.http.patch<any>(environment.apiUrl + "customers/" + id + "/active/true", {}).toPromise();
    }
     async getCountByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'customers/count', filter).toPromise();
    }
    async update(id: number, customer: any){
        return await this.http.put<any[]>(environment.apiUrl + 'customers/' + id, customer).toPromise();
    }
}

