import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { DevolutionPendency } from '../_models/index';

@Injectable()
export class DevolutionPendencyService {
    constructor(private http: HttpClient) { }

    async getByCompanyId(companyId: any, pageNo: any, pageSize: any, sortBy: string) {
        return await this.http.get<DevolutionPendency[]>(environment.apiUrl + 
            'devolutionpendencies?company_id=' + companyId + '&page_no=' + pageNo + '&page_size=' 
            + pageSize + '&sort_by=' + sortBy).toPromise();
    }

    getTotalByCompany(companyId: any) {
        return this.http.get<DevolutionPendency>(environment.apiUrl + 
            'devolutionpendencies/count/' + companyId);
    }

    resolvePendency(pendency: DevolutionPendency) {
        return this.http.put<DevolutionPendency>(environment.apiUrl + 'pendencies', pendency);
    }

    async confirm(devolutionPendency: DevolutionPendency) {
        return await this.http.patch<any>(environment.apiUrl + "devolutionpendencies/true", devolutionPendency).toPromise();
    }

    async confirmNotReceived(devolutionPendency: DevolutionPendency) {
        return await this.http.patch<any>(environment.apiUrl + "devolutionpendencies/false", devolutionPendency).toPromise();
    }
    remove(id: any) {
        return this.http.delete<any>(environment.apiUrl + 'devolutionpendencies/' + id).toPromise();
    }
}