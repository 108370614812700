import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FinancialMovementType } from '../_models/financial-movement-type';
import { environment } from '../../environments/environment';

@Injectable()
export class FinancialMovementTypeService {
    constructor(private http: HttpClient) { }

    async save(movementType: any){
        return await this.http.post<any[]>(environment.apiUrl + 'financialmovementtypes', movementType).toPromise();
    }

    async getByCompanyId(companyId: any){
        return this.http.get<FinancialMovementType[]>(environment.apiUrl + 'financialmovementtypes/' + companyId).toPromise();
    }

    async getByFinancialGroupId(financialGroupId: any){
        return this.http.get<FinancialMovementType[]>(environment.apiUrl + 'financialmovementtypes/filter?group_id=' + financialGroupId + "&group_type=0&credit_debit=false&company_id=0").toPromise();
    }

    async getByCompanyIdAndGroupType(companyId: any, groupType: any){
        return this.http.get<FinancialMovementType[]>(environment.apiUrl + 'financialmovementtypes/filter?group_id=0&credit_debit=false&company_id=' + companyId + "&group_type=" + groupType).toPromise();
    }

    async getByCompanyIdAndCreditDebit(companyId: any, type: any){
        return this.http.get<FinancialMovementType[]>(environment.apiUrl + 'financialmovementtypes/filter?group_id=0&company_id=' + companyId + "&group_type=0&credit_debit=" + type).toPromise();
    }

    async getByCompanyIdAndKey(companyId: any, key: string){
        return this.http.get<FinancialMovementType>(environment.apiUrl + 'financialmovementtypes?company_id=' + companyId + '&key=' + key).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'financialmovementtypes/filter', filter).toPromise();
    }

    async changeActive(id: any, financialMovementType: FinancialMovementType) {
        return await this.http.patch<any>(environment.apiUrl + "financialmovementtypes/" + id, financialMovementType).toPromise();
    }
    async getCountByFilter(filter: any){
      return await this.http.post<any[]>(environment.apiUrl + 'financialmovementtypes/count', filter).toPromise();
  }
}
