import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import "rxjs/add/operator/map";
import { environment } from "../../environments/environment";
import { Supplier } from "../_models/index";

@Injectable()
export class DigitalCertificatesService {
  constructor(private http: HttpClient) {}

  objectToFormData(obj, rootName, ignoreList) {
    var formData = new FormData();
    function appendFormData(data, root) {
        if (!ignore(root)) {
            root = root || '';
            if (data instanceof File) {
                formData.append(root, data);
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    appendFormData(data[i], root + '[' + i + ']');
                }
            } else if (typeof data === 'object' && data) {
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            appendFormData(data[key], key);
                        } else {
                            appendFormData(data[key], root + '.' + key);
                        }
                    }
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    }
    function ignore(root){
        return Array.isArray(ignoreList)
            && ignoreList.some(function(x) { return x === root; });
    }
    appendFormData(obj, rootName);
    return formData;
  }

  async getByFilter(filter: any) {
    return await this.http
      .post<any[]>(environment.apiUrl + "digitalcertificates/filter", filter)
      .toPromise();
  }
  async saveCertificateDigital(bodyCertificate: any) {
    let formData = this.objectToFormData(bodyCertificate, "", null);

    return await this.http
      .post<any[]>(environment.apiUrl + "digitalcertificates", formData)
      .toPromise();
  }
  async editCertificateDigital(idCertificate: number, bodyCertificate: any) {
    let formData = this.objectToFormData(bodyCertificate, "", null);
    return await this.http
      .post<any[]>(environment.apiUrl + `digitalcertificates/${idCertificate}`, formData)
      .toPromise();
  }
  async removeCertificateDigital(idCertificate: number) {
    return await this.http
      .delete<any[]>(environment.apiUrl + `digitalcertificates/${idCertificate}`)
      .toPromise();
  }
  async getCityOfBrazil(text: string){
    return await this.http.get<any[]>(`https://api.plataformax8.com.br/api/v1/cities?term=${text}`).toPromise();
}

}
