import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/first';

import { ModalDirective } from 'ngx-bootstrap/modal';

import { AuthenticationService } from './../../_services/index';
import { MustMatch } from '../../_helpers/must-match.validator';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'changepassword.component.html',
  styleUrls: ['changepassword.component.scss']
})
export class ChangePasswordComponent implements OnInit{ 

  @ViewChild('loadingModal', {static: true}) public loadingModal: ModalDirective;

  changePassForm: FormGroup;
  submitted = false;
  success = false;
  error = false;
  disableBtnEnviar = false;
  changeToken: string;
  validated: Boolean = false;
  showPassword = false;
  showConfirmPassword = false;

  email: string;

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private formBuilder: FormBuilder) { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.changeToken = params["changeToken"];
    });
  }

  ngOnInit() { 
    if (this.changeToken) {
      this.authenticationService.validateToken(this.changeToken)
      .then(
        data => {
          localStorage.setItem("token", this.changeToken);
        },
        err => {
          this.router.navigate(['/login']);
        }
      )
    }
    this.loadingModal;
    this.changePassForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$')
        ]
      ],
      confirmPassword: ['', Validators.required]
    }, {
        validator: MustMatch('password', 'confirmPassword')
    });
  }

  f() { return this.changePassForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.success = false;
    this.error = false;
    this.disableBtnEnviar = false;
  
    if (this.changePassForm.invalid) {
      return;
    }

    this.authenticationService.changePassword(this.changePassForm.controls.password.value)
      .first()
      .subscribe(
          data => {
            this.success = true;         
          },
          error => {
            this.error = true;
          })
      .add(() => {
        this.submitted = false;
        this.changePassForm.reset();  
        this.changePassForm.disable();
        this.disableBtnEnviar = true;
        this.authenticationService.logout();
        setTimeout(() => 
        {
            this.router.navigate(['/']);
        }, 5000)
      });      
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onReset() {
    this.submitted = false;
    this.changePassForm.reset();
    this.router.navigate(['login']);
  }
}
