<app-header
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/logo-header.png', width: 180, height: 30, alt: 'Plataforma X8'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/logo-header-mini.png', width: 180, height: 30, alt: 'Plataforma X8'}">
  <ul class="nav navbar-nav ml-auto">
    <li>Olá, <span class="user-name">{{userName}}</span></li>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <img src="assets/img/avatars/avatar.png" class="img-avatar-company" alt="admin@bootstrapmaster.com"/>
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-right-compacted" *dropdownMenu aria-labelledby="simple-dropdown">        
        <a class="dropdown-item" href="#" (click)="logout()"><i class="icon-logout"></i> Sair</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <!-- Main content -->
  <main class="main">
    <div class="container-fluid container-fluid-full">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>
</div>
<!--<app-footer>
  <span class="ml-auto"><a href="#">X8 Sales Manager</a></span>
</app-footer>-->
