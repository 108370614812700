import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';

@Injectable()
export class ReturnPurchaseService {
    constructor(private http: HttpClient) { }

    async save(returnPurchase: any){
        return await this.http.post<any[]>(environment.apiUrl + 'returnpurchases', returnPurchase).toPromise();
    }
}
