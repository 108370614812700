import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { QuickAnswer } from '../_models';

@Injectable()
export class QuickAnswerService {
    constructor(private http: HttpClient) { }

    async create(quickanswer: any) {
        return await this.http.post<any[]>(environment.apiUrl + 'quickanswers', quickanswer).toPromise();
    }
    
    async update(quickanswer: any) {
        return await this.http.put<any[]>(environment.apiUrl + 'quickanswers', quickanswer).toPromise();
    }

    async delete(id: any) {
        return await this.http.delete<any>(environment.apiUrl + "quickanswers/" + id).toPromise();
    }

    async getByFilter(filter: any) {
        return await this.http.post<any[]>(environment.apiUrl + 'quickanswers/filter', filter).toPromise();
    }

    getByShortcutOrText(companyId: any, shortcut: string, text: string) {
        return this.http.get<QuickAnswer[]>(environment.apiUrl + 
            'quickanswers?company_id=' + companyId + '&shortcut=' + shortcut + '&text=' + text);
    }
}
