import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FinancialMovementReportControl } from '../_models/financial-movement-report-control';

@Injectable()
export class FinancialMovementReportControlService {
    constructor(private http: HttpClient) { }
    
    async save(reportControl: any){
        return await this.http.put<any>(environment.apiUrl + 'financialmovementreportcontrol', reportControl).toPromise();
    }

    getById(id: any){
        return this.http.get<FinancialMovementReportControl>(environment.apiUrl + 'financialmovementreportcontrol/' + id).toPromise();
    }

    getByCompanyId(companyId: any){
        return this.http.get<FinancialMovementReportControl[]>(environment.apiUrl + 'financialmovementreportcontrol?company_id=' + companyId).toPromise();
    }

    getPendingByCompanyId(companyId: any){
        return this.http.get<FinancialMovementReportControl[]>(environment.apiUrl + 'financialmovementreportcontrol/pending?company_id=' + companyId).toPromise();
    }
}