import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { OrderItemPendency } from '../_models/index';

@Injectable()
export class OrderItemPendencyService {
    constructor(private http: HttpClient) { }

    async getByCompanyId(companyId: any, pageNo: any, pageSize: any, sortBy: string) {
        return await this.http.get<OrderItemPendency[]>(environment.apiUrl +
            'orderitempendencies?company_id=' + companyId + '&page_no=' + pageNo + '&page_size=' + pageSize + '&sort_by=' + sortBy)
            .toPromise();
    }

    getTotalByCompany(companyId: any) {
        return this.http.get<OrderItemPendency>(environment.apiUrl + 'orderitempendencies/count/' + companyId);
    }

    resolvePendency(pendency: OrderItemPendency, id: any) {

        return this.http.put<OrderItemPendency>(environment.apiUrl + 'orderitempendencies/' + id, pendency);
    }

    removeOrderItemPendency(id: any) {
        return this.http.delete<OrderItemPendency>(environment.apiUrl + 'orderitempendencies/' + id).toPromise();
    }

    async getFilterPendency(filter: any) {
      return await this.http
        .post<any[]>(environment.apiUrl + "orderitempendencies/filter", filter)
        .toPromise();
    }
}
