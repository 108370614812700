import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

export const TOKEN_NAME: string = 'token';
export const REFRESH_TOKEN_NAME: string = 'refreshToken';

@Injectable()
export class AssetsHelper {

  private url: string = 'api/auth';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    
  public marketplaces = [
    {
      platform: "MERCADO_LIVRE",
      description: "Mercado Livre"
    }];

  constructor(
    private http: HttpClient, 
    private router: Router) { }

    getMarketplaces() {
      return this.marketplaces;
    }

    getPlatformDescription(type: any) {
      switch(type){
        case 'MERCADO_LIVRE':
          return "Mercado Livre";
        case 'MAGAZINE_LUIZA':
          return "Magazine Luiza";
        case 'SHOPEE':
          return "Shopee";
        case 'B2W':
          return "B2W";
        case 'VIA_VAREJO':
          return "Via Varejo";
        case 'AMAZON':
          return "Amazon";
        case 'DAFITI':
          return "Dafiti";
        case 'OWN_WEBSITE':
          return "Site Próprio";
        case 'PHYSICAL_STORE':
        return "Loja Física";
        case 'SHEIN':
        return "shein";
        break;
      }
  }

  getPlatformLogo(type: any) {
      switch(type){
        case 'MERCADO_LIVRE':
          return "./../../../assets/img/mercadolivre_logo.png";
        case 'MAGAZINE_LUIZA':
          return "./../../../assets/img/magazine_luiza_logo.png";
        case 'SHOPEE':
          return "./../../../assets/img/shopee_logo.png";
        case 'PHYSICAL_STORE':
          return "./../../../assets/img/physical_store_logo.png";
        case 'NETSHOES':
          return "./../../../assets/img/netshoes-logo.png";
          case 'SHEIN':
            return "./../../../assets/img/shein.logo.png"
        break;
      }
  }

}