import { AnnouncementProduct } from './announcement-product';
import { Category } from './category';
import { Company } from './company';
import { ProductDatasheet } from './product-datasheet';
import { ProductKit } from './product-kit';
import { ProductPriceList } from './product-price-list';
import { ProdutcVariation } from './product-variation';
import { ProductVariationType } from './product-variation-type';

export class Product {

    id: any;
    title: string;
    sku: string;
    ean: string;
    description: string;
    type: string;
    variationDescription: string;
    stock: any;
    minimumStock: any;
    brand: string;
    lastPrice: any;
    averageCostPrice: any;
    price: any;
    volumes: any;
    itemsPerBox: any;
    width: any;
    height: any;
    depth: any;
    netWeight: any;
    grossWeight: any;
    unitMeasurement: any;
    category: Category;
    preparationDays: any;
    warranty: any;
    warrantyType: string;
    taxableEan: string;
    ipiCode: string;
    ipiValue: any;
    icmsOrigin: any;
    cestCode: any;
    ncm: any;
    companyId: any;
    company: Company;
    active: boolean;
    products: Product[];
    product: Product;
    video: any;
    videoYoutubeUrl: string;
    videoUrl: string;
    variationTypes: ProductVariationType[];
    variation: ProdutcVariation;
    datasheets: ProductDatasheet[];
    priceList: ProductPriceList[];
    productKits: ProductKit[];
    announcements: AnnouncementProduct[];
}
