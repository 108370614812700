import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertSystemComponent } from './alert-system.component';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
  declarations: [AlertSystemComponent],
  imports: [
    CommonModule,
    AlertModule.forRoot(),
  ],
  exports: [AlertSystemComponent]
})
export class AlertSystemModule { }
