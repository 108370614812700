import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class CepService {
    constructor(private http: HttpClient) { }

    getAddressByCep(cep: string) {
        return this.http.get<any[]>("https://viacep.com.br/ws/" + cep + "/json/").toPromise();
    }
}
