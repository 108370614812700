import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Product, ProductDatasheet } from '../_models/index';

@Injectable()
export class ProductService {
    constructor(private http: HttpClient) { }

    jsonToFormData (inJSON, inTestJSON, inFormData, parentKey) {
        var form_data = inFormData || new FormData();
        var testJSON = inTestJSON || {};
        for ( var key in inJSON ) {
            var constructedKey = key;
            if (parentKey) {
                constructedKey = parentKey + "." + key;
            }
            var value = inJSON[key];
            console.log(value);
            if (value && value.constructor === {}.constructor) {
                this.jsonToFormData (value, testJSON, form_data, constructedKey);
            } else {
                form_data.append(constructedKey, inJSON[key]);
                testJSON[constructedKey] = inJSON[key];
            }
        }
        return form_data;
    }

    objectToFormData(obj, rootName, ignoreList) {
        var formData = new FormData();
        function appendFormData(data, root) {
            if (!ignore(root)) {
                root = root || '';
                if (data instanceof File) {
                    formData.append(root, data);
                } else if (Array.isArray(data)) {
                    for (var i = 0; i < data.length; i++) {
                        appendFormData(data[i], root + '[' + i + ']');
                    }
                } else if (typeof data === 'object' && data) {
                    for (var key in data) {
                        if (data.hasOwnProperty(key)) {
                            if (root === '') {
                                appendFormData(data[key], key);
                            } else {
                                appendFormData(data[key], root + '.' + key);
                            }
                        }
                    }
                } else {
                    if (data !== null && typeof data !== 'undefined') {
                        formData.append(root, data);
                    }
                }
            }
        }
        function ignore(root){
            return Array.isArray(ignoreList)
                && ignoreList.some(function(x) { return x === root; });
        }
        appendFormData(obj, rootName);
        return formData;
    }

    async save(product: any) {
        let formData = this.objectToFormData(product, "", null);
        return await this.http.post<any[]>(environment.apiUrl + 'products', formData).toPromise();
    }

    async update(product: any) {
        let formData = this.objectToFormData(product, "", null);
        return await this.http.post<any>(environment.apiUrl + 'products/' + product.id, formData).toPromise();
    }

    async patchProducts(ids: any[], properties: any) {
        return this.http.patch<any>(environment.apiUrl + "products?ids=" + ids, properties).toPromise();
    }

    async deleteImage(id: any, imageId: any) {
        return await this.http.delete(environment.apiUrl + 'products/' + id + "/images/" + imageId).toPromise();
    }

    async updateVariation(id: any, variationId: any, product: Product) {
        return await this.http.put<Product>(
            environment.apiUrl + 'products/' + id + "/variations/" + variationId, product).toPromise();
    }

    async saveAnnouncementLink(id: any, announcementIds: Array<any>) {
        return await this.http.post<any[]>(
            environment.apiUrl + 'products/' + id + "/announcements", announcementIds).toPromise();
    }

    async getAnnouncementLink(id: any) {
        return await this.http.get<any[]>(environment.apiUrl + 'products/' + id + "/announcements").toPromise();
    }

    async deleteAnnouncementLink(id: any, announcementId: any) {
        return await this.http.delete<any[]>(
            environment.apiUrl + 'products/' + id + "/announcements/" + announcementId).toPromise();
    }

    async saveDatasheet(id: any, datasheet: any) {
        return await this.http.post<any[]>(
            environment.apiUrl + 'products/' + id + "/datasheets", datasheet).toPromise();
    }

    async getDatasheets(id: any) {
        return await this.http.get<any[]>(environment.apiUrl + 'products/' + id + "/datasheets").toPromise();
    }

    async updateDatasheet(id: any, datasheetId: any, datasheet: ProductDatasheet) {
        return await this.http.put<any[]>(
            environment.apiUrl + 'products/' + id + "/datasheets/" + datasheetId, datasheet).toPromise();
    }

    async deleteDatasheet(id: any, datasheetId: any) {
        return await this.http.delete<any[]>(
            environment.apiUrl + 'products/' + id + "/datasheets/" + datasheetId).toPromise();
    }

    async getByCompanyIdProducts(companyId: any) {
      return await this.http.get<Product[]>(environment.apiUrl + 'products/summarybestsellers?company_id=' + companyId).toPromise();
    }

    async getKits(id: any) {
        return await this.http.get<any[]>(environment.apiUrl + 'products/' + id + "/kits").toPromise();
    }

    async saveKit(id: any, kit: any) {
        return await this.http.post(environment.apiUrl + 'products/' + id + "/kits", kit).toPromise();
    }

    async deleteKit(id: any, kitId: any) {
        return await this.http.delete(environment.apiUrl + 'products/' + id + "/kits/" + kitId).toPromise();
    }

    async saveAll(products: any[]){
        return await this.http.post<any[]>(environment.apiUrl + 'products/all', products).toPromise();
    }

    async activate(id: any){
        return await this.http.patch<any[]>(environment.apiUrl + 'products/' + id, {'active': true}).toPromise();
    }

    async deactivate(id: any) {
        return await this.http.patch<any>(environment.apiUrl + "products/" + id, {'active': false}).toPromise();
    }

    async getByCompanyId(companyId: any) {
        return await this.http.get<Product[]>(environment.apiUrl + 'products?company_id=' + companyId).toPromise();
    }

    getByDescriptionOrSku(companyId: any, title: string, sku: string) {
        let url = 'products?company_id=' + companyId;
        if (title) url += '&title=' + title;
        if (sku) url += '&sku=' + sku;
        return this.http.get<Product[]>(environment.apiUrl + url);
    }

    getByDescriptionOrSkuOrStockId(companyId: any, title: string, sku: string, stockId: any) {
        let url = 'products?company_id=' + companyId;
        if (title) url += '&title=' + title;
        if (sku) url += '&sku=' + sku;
        if (stockId) url += '&stock_id=' + stockId;
        return this.http.get<Product[]>(environment.apiUrl + url);
    }

    getByDescriptionOrSkuAndJustCover(companyId: any, title: string, sku: string, justCover: boolean) {
        sku ? sku = sku.replace("+", "%2B") : sku;
        let url = 'products?company_id=' + companyId;
        if (title) url += '&title=' + title;
        if (sku) url += '&sku=' + sku;
        if (justCover !== null) url += '&just_cover=' + justCover;
        return this.http.get<Product[]>(environment.apiUrl + url);
    }

    importationFile(importationBody: any) {
        let formData = this.objectToFormData(importationBody, "", null);
        return this.http.post<any>(`${environment.apiUrl}products/importation`, formData);
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'products/filter', filter).toPromise();
    }
    async getCountByFilter(filter: any){
      return await this.http.post<any[]>(environment.apiUrl + 'products/count', filter).toPromise();
  }
}
