import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { ShopeeSign } from '../_models/shopee-sign';

@Injectable()
export class X8Service {
    constructor(private http: HttpClient) { }

    async shopeeSign(shopeeSign: ShopeeSign) {
        return await this.http.post(environment.apiUrl + 'x8/signs/shopee', shopeeSign, {responseType: 'text'}).toPromise();
    }
}
