import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Message } from '../_models';
import { ConversationMessage } from '../_models/conversation-message';

@Injectable()
export class MessageService {
    constructor(private http: HttpClient) { }

    async save(product: any){
        return await this.http.post<any[]>(environment.apiUrl + 'products', product).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'orderpackages/conversations', filter).toPromise();
    }

    async send(message: Message, messageId: any) {
        const formData: FormData = new FormData();

        message.files.forEach(file => {
            formData.append('files', file);
        });

        formData.append('text', message.text);
        formData.append('messageReference', message.messageReference);
        formData.append('sentBy', message.sentBy);
        formData.append('receivedBy', message.receivedBy);

        console.log(formData);

        return await this.http.post<any>(`${environment.apiUrl}messages/${messageId}/send`, formData).toPromise();
    }

    async read(id: any) {
        return await this.http.get<ConversationMessage>(environment.apiUrl + "messages/" + id + "/read").toPromise();
    }

    openAttachment(id: any, platformId: any) {
        const httpOptions = {
            responseType: 'blob' as 'json'
          };
        return this.http.get<any>(
            environment.apiUrl + "messages/attachments/" + id + "?platform_id=" + platformId, httpOptions);
    }
}
