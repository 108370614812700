import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

export const TOKEN_NAME: string = 'token';
export const REFRESH_TOKEN_NAME: string = 'refreshToken';

@Injectable()
export class AuthService {

  private url: string = 'api/auth';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient, 
    private router: Router) { }

  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;
    const date = new Date(0); 
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token?: string): boolean {
    if(!token) token = this.getToken();
    if(!token) return true;
    
    const date = this.getTokenExpirationDate(token);
    if(date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  async refreshToken() {
    if (this.isTokenExpired() && this.getRefreshToken()) {
      await this.http
      .post<any>(environment.apiUrl + 'login/refreshtoken', this.getRefreshToken(), { headers: this.headers })
      .toPromise()
      .then( 
        data => {
          if (data) {
            localStorage.setItem('token', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
          }
        }
      )
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  isDefaulting() {
    if (this.getLoggedUser() !== null) {
      return this.getLoggedUser().company.defaulting;
    } else {
      true;
    }
  }

  async redirectToPaymentIfIsDefaulting() {
    if(this.getLoggedUser().company.defaulting) {
      this.router.navigate(["/account"]);
    }
  }

  login(user): Promise<string> {
    return this.http
      .post(`${this.url}/login`, JSON.stringify(user), { headers: this.headers })
      .toPromise()
      .then(res => JSON.stringify(res));
  }

  getLoggedUser(){
    const user = localStorage.getItem("user");
    if(user){
      return JSON.parse(user);
    }
    return null;
  }

  getCompanyId(){
    const user = localStorage.getItem("user");
    if(user){
      const parsed = JSON.parse(user);
      return parsed.company.id;
    }
    return null;
  }

}