
import { Order } from './order';
import { OrderItem } from './order-item';

export class OrderItemPendency {

    id: any;
    resolutionDate: any;
    order: Order;
    item: OrderItem;
}