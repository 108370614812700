import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Announcement } from '../_models/announcement';
import { environment } from '../../environments/environment';

@Injectable()
export class AnnouncementService {
  constructor(private http: HttpClient) {}

  getById(id: any) {
    return this.http.get<Announcement>(
      environment.apiUrl + 'announcements/' + id
    );
  }

  getByPlatformIdAndReference(platformId: any, marketplaceReference: string) {
    return this.http
      .get<Announcement>(
        environment.apiUrl +
          'announcements?platform_id=' +
          platformId +
          '&marketplace_reference=' +
          marketplaceReference
      )
      .toPromise();
  }

  getByCompanyIdAndReference(companyId: any, marketplaceReference: string) {
    return this.http
      .get<Announcement>(
        environment.apiUrl +
          'announcements?company_id=' +
          companyId +
          '&marketplace_reference=' +
          marketplaceReference
      )
      .toPromise();
  }

  getByReferenceAndSourceAndCompany(marketplaceReference: string, marketplaceSourceId: any, companyId: any) {
    return this.http
      .get<Announcement>(
        environment.apiUrl +
          'announcements?company_id=' +
          companyId +
          '&marketplace_reference=' +
          marketplaceReference +
          '&marketplace_source_id=' +
          marketplaceSourceId
      )
      .toPromise();
  }

  async getByFilter(filter: any) {
    return await this.http
      .post<any[]>(environment.apiUrl + 'announcements/filter', filter)
      .toPromise();
  }

  getByTitle(filter: any) {
    return this.http.post<any[]>(
      environment.apiUrl + 'announcements/filter',
      filter
    );
  }

  async update(announcement: Announcement) {
    return this.http
      .put<Announcement>(environment.apiUrl + 'announcements', announcement)
      .toPromise();
  }

  async updatePrice(announcementId: any, price: any) {
    return this.http
      .patch<Announcement>(
        environment.apiUrl + 'announcements/' + announcementId,
        { price: price }
        )
      .toPromise();
    }

    async updateCost(announcementId: any, cost: any) {
      return this.http
      .patch<Announcement>(
        environment.apiUrl + 'announcements/' + announcementId,
        { cost: cost }
      )
      .toPromise();
    }

    async updateStock(announcementId: any, stock: any) {
      return this.http
        .patch<Announcement>(
          environment.apiUrl + 'announcements/' + announcementId,
          { stock: stock }
        )
        .toPromise();
    }

    async updateProducts(announcement: Announcement) {
      return this.http
      .patch<Announcement>(environment.apiUrl + "announcements", announcement)
      .toPromise();
    }

    async updateAll(announcements: Announcement[]) {
    return this.http
      .put<Announcement>(
        environment.apiUrl + 'announcements/all',
        announcements
        )
      .toPromise();
    }

    async getCountByFilter(filter: any) {
      return await this.http
        .post<any[]>(environment.apiUrl + "announcements/count", filter)
        .toPromise();
    }

    async queueImportation(marketplaceSourceId: any) {
      return await this.http
        .post<any>(environment.apiUrl + 'announcements/queueimportation?accountId=' + marketplaceSourceId, null)
        .toPromise();
    }

    async queueImportationByTopic(marketplaceSourceId: any, messageTopic: string, companyId: any) {
      return await this.http
        .post<any>(environment.apiUrl + 'announcements/queueimportation?accountId='
          + marketplaceSourceId + "&topic=" + messageTopic, companyId)
        .toPromise();
    }

    async queueUpdate(marketplaceSourceId: any, announcements: Announcement[]) {
      return await this.http
        .post<any>(environment.apiUrl + 'announcements/queueupdate?accountId=' + marketplaceSourceId, announcements)
        .toPromise();
    }

    async getByCompanyIdAnnouncements(companyId: any) {
      return await this.http.get<Announcement[]>(environment.apiUrl + 'announcements/summarybestsellers?company_id=' + companyId).toPromise();
    }
  }
