import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/first';

import { AuthenticationService, UserService } from './../../_services/index';
import { User } from './../../_models/index';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../_guards';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {

  user: User;
  loading = false;
  returnUrl: string;
  error = '';
  loginForm: FormGroup;
  submitted = false;
  btnLoginDisabled = false;
  verificationToken: string;
  verificated = false;
  verificationError = false;

  constructor(
      private activatedRoute: ActivatedRoute,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private authService: AuthService,
      private userService: UserService,
      private router: Router,
      private authenticationService: AuthenticationService,
      private toastr: ToastrService) {

        this.activatedRoute.queryParams.subscribe(params => {
          this.verificationToken = params["verificationToken"];
        });
       }

  ngOnInit() {
    
    if (this.verificationToken) {
      this.authenticationService.activate(this.verificationToken)
      .then(
        data => {
          this.router.navigate(['/login']);
          this.verificated = true;
        },
        err => {
          this.verificationError = true;
        }
      )
    }

    this.user = new User();
    // reset login status
    this.authenticationService.logout();      
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]]
    });
  }

  onReset() {
    this.submitted = false;
    this.verificated = false;
    this.router.navigate(['login']);
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    
    this.btnLoginDisabled = true;
    this.loading = true;
    this.authenticationService.login(this.loginForm.controls.login.value, this.loginForm.controls.password.value)
        .first()
        .subscribe(
            async data => {
              this.btnLoginDisabled = false;
  
              const user = this.authService.getLoggedUser();
              if (user && user.id) {
                const response = await this.userService.getUserById(user.id);
  
                let userDetails;
                if (Array.isArray(response)) {
                  if (response.length == 0) {
                    this.router.navigate([this.returnUrl]);
                    return;
                    }
                    userDetails = response[0];
                } else {
                  userDetails = response;
                }
                const isTwoFactorEnabled = userDetails?.enableTwoFactorToken;
  
                if (isTwoFactorEnabled) {
                  this.router.navigate(['/two-factor-auth']);
                } else {
                  this.router.navigate([this.returnUrl]);
                }
              } else {
                console.error('Logged user ID not found');
                this.router.navigate([this.returnUrl]);
              }
            },
            error => {
              this.error = error;
              this.btnLoginDisabled = false;
              this.loading = false;
              this.toastr.error("Credenciais inválidas, não foi possível efetuar o login!");
            });
  }

  forgotPassword() {
    this.router.navigate(['/forgotpassword']);
  }

  register() {
    window.location.href = "https://www.plataformax8.com.br";
  }
}
