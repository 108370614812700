import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map'
import decode from 'jwt-decode';
import { environment } from '../../environments/environment';
import { AuthService } from '../_guards/index';

@Injectable()
export class AuthenticationService {
    constructor(private http: HttpClient, private authService: AuthService) { }

    login(login: string, password: string) {
        return this.http.post<any>(environment.apiUrl + 'login', { login: login, password: password })
            .map(data => {
                if (data && data.token && data.refreshToken) {
                    this.authService.setToken(data.token);
                    const claims = decode(data.token);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    localStorage.setItem('user', JSON.stringify(claims.user));
                }
                return data;
            });
    }

    forgotPassword(email: string) {
        return this.http.patch<any>(environment.apiUrl + 'login/forgotpassword', { email: email })
            .map(data => {
                if (data && data.token) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    const claims = decode(data.token);
                    localStorage.setItem('user', JSON.stringify(claims.user));
                }
                return data;
            });
    }

    changePassword(password: string) {
        return this.http.patch<any>(environment.apiUrl + 'login/changepassword', { password: password })
            .map(data => {
               
            });
    }

    activate(token: string) {
        return this.http.put<any>(environment.apiUrl + 'login/activation', token).toPromise();
    }

    validateToken(token: string) {
        return this.http.post<any>(environment.apiUrl + 'login/tokenvalidation', token).toPromise();
    }

    refreshToken(token: any) {
        return this.http.post<any>(environment.apiUrl + 'login/refreshtoken', token).toPromise();
    }

    logout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
    }
}