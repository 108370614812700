import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable()
export class InvoicesService {
    constructor(private http: HttpClient) { }

    async getByFilter(bodyInvoices: any){
        return await this.http.post<any[]>(environment.apiUrl + 'invoices/filter', bodyInvoices).toPromise();
    }

    async saveInvoice(bodyInvoices: any) {
        return await this.http.post<any[]>(environment.apiUrl + 'invoices', bodyInvoices).toPromise();
    }

    async editInvoice(idInvoice: number, bodyInvoices: any){
        return await this.http.put<any[]>(environment.apiUrl + `invoices/${idInvoice}`, bodyInvoices).toPromise();
    }

    async deleteById(id: number) {
        return await this.http.delete(environment.apiUrl + `invoices/${id}`).toPromise();
    }

    async saveInvoiceItem(idInvoice: number, bodyItem: any){
        return await this.http.post<any[]>(environment.apiUrl + `invoices/${idInvoice}/items`, bodyItem).toPromise();
    }

    async createIssue(bodyItem: any) {
        return await this.http.post<any>(environment.apiUrl + `invoices/` + bodyItem.id + `/issuances`, bodyItem).toPromise();
    }

    async createCorrection(bodyItem: any) {
        return await this.http.post<any>(environment.apiUrl + `invoices/` + bodyItem.id + `/corrections`, bodyItem).toPromise();
    }

    async createCancellation(bodyItem: any) {
        return await this.http.put<any>(environment.apiUrl + `invoices/` + bodyItem.id + `/cancellations`, bodyItem).toPromise();
    }

    async editInvoiceItem(idInvoice: number, idItem: number, bodyItem: any){
        return await this.http.put<any[]>(environment.apiUrl + `invoices/${idInvoice}/items/${idItem}`, bodyItem).toPromise();
    }

    async deleteInvoiceItem(idInvoice: number, idItem: number){
        return await this.http.delete<any[]>(environment.apiUrl + `invoices/${idInvoice}/items/${idItem}`).toPromise();
    }

    async getNextNumber(digitalCertificateId: number) {
        return await this.http.get<any>(environment.apiUrl + `invoices/digitalcertificates/${digitalCertificateId}/number`).toPromise();
    }

    getDanfePdf(id: number) {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get<any>(environment.apiUrl + `invoices/${id}/pdf`, { headers: headers, responseType: 'blob' as 'json' });
    }

    async getCityOfBrazil(text?: string){
        return await this.http.get<any[]>(`https://api.plataformax8.com.br/api/v1/cities?term=${text ? text : ''}`).toPromise();
    }

    async getCountByFilter(filter: any) {
      return await this.http.post<any>(environment.apiUrl + 'invoices/count', filter).toPromise();
    }

    async getById(idInvoice: number) {
      return await this.http.get(environment.apiUrl + `invoices/${idInvoice}`).toPromise();
  }
}
