import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map'
import decode from 'jwt-decode';
import { environment } from '../../environments/environment';
import { Notification } from '../_models/index';

@Injectable()
export class NotificationService {
    constructor(private http: HttpClient) { }

    getByCompany(companyId: any) {
        return this.http.get<Notification[]>(environment.apiUrl + 'notifications?company_id=' + companyId)
            .map(data => {
                return data;
            });
    }

    forgotPassword(email: string) {
        return this.http.patch<any>(environment.apiUrl + 'login/forgotpassword', { email: email })
            .map(data => {
                if (data && data.token) {
                    localStorage.setItem('token', data.token);
                    const claims = decode(data.token);
                    localStorage.setItem('user', JSON.stringify(claims.user));
                }
                return data;
            });
    }

    visualize(notification: Notification) {
        return this.http.patch<any>(environment.apiUrl + 'notifications', { id: notification.id, visualized: true });
    }

    clearAll(companyId: any) {
        return this.http.put<any>(environment.apiUrl + 'notifications/' + companyId, {}).toPromise();
    }
    notificationsFilter(filter: any) {
      return this.http.post<any>(environment.apiUrl + 'notifications/filter', filter).toPromise();
  }
}
