import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AnnouncementService } from '../../../_services';  
import { Announcement } from '../../../_models';
import { Router } from '@angular/router';
import { AuthService } from '../../../_guards';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-stock-fulfillment',
  templateUrl: './stock-fulfillment.component.html',
  styleUrls: ['./stock-fulfillment.component.scss']
})
export class StockFulfillmentComponent implements OnInit {
  announcements: Array<Announcement> = new Array();
  displayedColumns: string[] = ['platform', 'nickname', 'fulfillmentStock'];
  selectedAnnouncements: Announcement;
  fulfillmentModal: BsModalRef;
  announcementId: any;
  totalPages: any;
  submitted: boolean = false;

  constructor(
    public announcementService: AnnouncementService,
    public bsModalRef: BsModalRef,
    public router: Router,
    public authService: AuthService,
    private bsModalService: BsModalService,
    private toastr: ToastrService,
  ) {}
  
  filterAnnouncements() {
    this.announcementId = this.announcementService.getById("id")
    let filter = {
      announcementId: this.announcementId,
      pagination: {
        pageNo: 0,
        pageSize: 10,
        sortBy: "id",
      },
      type: ""
    }
    return filter;
  }

  private async findAnnouncementFilter() {
    let filter = this.filterAnnouncements();
    await this.announcementService.getByFilter(filter)
    .then(
      data => {
        this.announcements = data["records"];
        this.totalPages = data["total"];
      }, () => {
        this.toastr.error("Ocorreu um problema ao recuperar a informação dos servidores.")
      }
    );
    this.submitted = false;
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  ngOnInit(): void {

  }
}
