import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Answer } from '../_models';

@Injectable()
export class QuestionService {
    constructor(private http: HttpClient) { }

    async save(product: any){
        return await this.http.post<any[]>(environment.apiUrl + 'products', product).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'questions/filter', filter).toPromise();
    }

    async answer(answer: Answer, id: any) {
        return await this.http.put(environment.apiUrl + "questions/" + id + "/answer", answer).toPromise();
    }

    async delete(id: any) {
        return await this.http.delete(environment.apiUrl + "questions/" + id).toPromise();
    }
}
