import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { CompanyPreference } from '../_models/companyPreference';

@Injectable()
export class CompanyPreferencesService {
    constructor(private http: HttpClient) { }

    async getPreferences(companyId: number) {
        return await this.http.get<CompanyPreference[]>(environment.apiUrl + `companypreferences?company_id=${companyId}`).toPromise();
    }

    async updatePreferences(id: number, valueCheck: any) {
      return await this.http.patch(environment.apiUrl + `companypreferences/${id}`, valueCheck).toPromise();
    }
}
