import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import "rxjs/add/operator/map";
import { environment } from "../../environments/environment";
import { Communication } from "../_models/communication";

@Injectable()
export class CommunicationService {
  constructor(private http: HttpClient) {}

  async getByFilter(filter: any) {
    return await this.http
      .post<any[]>(environment.apiUrl + "communications/filter", filter)
      .toPromise();
  }

  async sendMessage(bodyMessage: any, id: number) {

    const formData: FormData = new FormData();

    bodyMessage.files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('text', bodyMessage.text);
    formData.append('questionId', bodyMessage.questionId);

    return await this.http
      .post<any[]>(environment.apiUrl + `communications/${id}/messages`, formData)
      .toPromise();
  }

  async getMessegeById(id: number) {
    return await this.http
      .get<Communication>(environment.apiUrl + `communications/${id}`)
      .toPromise();
  }

  async makePendingOrSolved(idCommunication: number, bodyStatus: any) {
    return await this.http
      .patch<Communication>(environment.apiUrl + `communications/${idCommunication}`, bodyStatus)
      .toPromise();
  }

  openAttachment(id: any, attachmentId: any) {
    const httpOptions = {
        responseType: 'blob' as 'json'
      };
    return this.http.get<any>(
        environment.apiUrl + `communications/${id}/attachments/${attachmentId}`, httpOptions);
  }

  async getBuyerQuestions(itemId: string, buyerId: string) {
    return await this.http
      .get<string>(`${environment.apiUrl}/questions/buyer-questions?itemId=${itemId}&buyerId=${buyerId}`)
      .toPromise();
  }

}
