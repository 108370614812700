import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { AutomaticMessage } from '../_models/automatic-message';

@Injectable()
export class AutomaticMessageService {
    constructor(private http: HttpClient) { }

    async save(message: any){
        return await this.http.post<AutomaticMessage>(environment.apiUrl + 'automaticmessages', message).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'automaticmessages/filter', filter).toPromise();
    }

    async activate(id: any) {
        return await this.http.patch<any>(environment.apiUrl + "automaticmessages/" + id + "/active/true", {}).toPromise();
    }

    async deactivate(id: any) {
        return await this.http.patch<any>(environment.apiUrl + "automaticmessages/" + id + "/active/false", {}).toPromise();
    }
}
