import { Company } from "./company";

export class FinancialMovementTypeGroup {

    id: any;
    description: string;
    type: any;
    active: boolean;
    considerResult: boolean;
    company: Company;
}