import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FinancialMovementTypeGroup } from '../_models/financial-movement-type-group';
import { environment } from '../../environments/environment';

@Injectable()
export class FinancialMovementTypeGroupService {
    constructor(private http: HttpClient) { }

    async save(movementGroupType: any){
        return await this.http.post<any[]>(environment.apiUrl + 'financialmovementtypegroups', movementGroupType).toPromise();
    }

    getByCompanyId(companyId: any){
        return this.http.get<FinancialMovementTypeGroup[]>(environment.apiUrl + 'financialmovementtypegroups/' + companyId).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'financialmovementtypegroups/filter', filter).toPromise();
    }

    async changeActive(id: any, financialMovementTypeGroup: FinancialMovementTypeGroup) {
        return await this.http.patch<any>(environment.apiUrl + "financialmovementtypegroups/" + id, financialMovementTypeGroup).toPromise();
    }
    async getCountByFilter(filter: any) {
      return this.http.post<any>(environment.apiUrl + 'financialmovementtypegroups/count', filter).toPromise();
    }
}
