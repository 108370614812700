import { Injectable } from "@angular/core";
import { AuthService } from "../_guards";
import { UserService } from "../_services";

interface MenuItem {
  id: number;
  description: string;
  url: string;
  icon: string;
  cssClass: string;
}

@Injectable()
export class MenuHelper {
  public menuItems: any[];
  public userLogged: any;
  public childsMenu: any[];
  public userAllowedMenu: any;

  public ArrayMainMenu = [];

  constructor(
    private authService: AuthService,
    private user: UserService) {
    this.userLogged = this.authService.getLoggedUser();
  }

  async getUser(){
    await this.user.getUserById(this.userLogged.id).then(
      (data:any) => {
        this.userAllowedMenu = data.userGroup
      }
    );
  }

  async getMenu() {
    await this.getUser();

    let ArrayGroupUser = [this.userAllowedMenu];

    let allMenusRoot = this.getAllSystemMenuRoots(ArrayGroupUser);
    let filterUniqueMenuRoot = this.filterUniqueById(allMenusRoot);
    let mainMenuUnique = this.replaceDescriptionWithName(filterUniqueMenuRoot);

    let menuFInal = this.getMenuFinal(mainMenuUnique, ArrayGroupUser);

    return this.moveDashboardToFront(menuFInal);


  }

  moveDashboardToFront(menuFinal) {
    const index = menuFinal.findIndex(obj => obj.id === 9);
    if (index !== -1) {
      const objectWithId9 = menuFinal[index];
      menuFinal.splice(index, 1);
      menuFinal.unshift(objectWithId9);
    }
    return menuFinal;
  }

  getMenuFinal(mainMenu, ArrayGroupUser) {
    let arrayResultSubMenus = [];

    ArrayGroupUser.forEach(group => {
      if (group?.systemMenus) {
        group.systemMenus.forEach(menu => {
          if (menu.systemMenu.systemMenuRoot) {
            const systemMenuRoot = mainMenu.find(main => main.id === menu.systemMenu?.systemMenuRoot.id);

            if (systemMenuRoot) {
              arrayResultSubMenus.push(menu.systemMenu);
            }

          } else {
            arrayResultSubMenus.push({
              id: menu.systemMenu.id,
              description: menu.systemMenu.description,
              url: menu.systemMenu.url
            });
          }
        });
      }
    });

    let resultMenu =  this.addChildrenToMenuRoot(mainMenu, arrayResultSubMenus);
    return resultMenu;
  }

  filterUniqueById(arr: any): any {
    return arr.filter((obj: MenuItem, index: number) => {
      return arr.findIndex((obj2: MenuItem) => obj2.id === obj.id) === index;
    });
  }

  replaceDescriptionWithName(array: any[]) {
    return array.map(item => {
      let menuItem = {
        id: item.id,
        name: item.description,
        url: item.url,
        icon: item.icon,
      };
      if(item.cssClass){
        menuItem["cssClass"] = item.cssClass
      }
      return menuItem;
    });
  }

  addChildrenToMenuRoot(ArrayResultRootMenu, ArrayResultSubmenus) {
    return ArrayResultRootMenu.map(menuRaiz => {
      const children = ArrayResultSubmenus.filter(submenu => {
        return submenu.systemMenuRoot?.id === menuRaiz.id;
      }).map(submenu => {
        return {
          id: submenu.id,
          name: submenu.description,
          url: submenu.url,
          icon: submenu.icon,
          cssClass: submenu.cssClass
        };
      });

      let rootMenu = {
        ...menuRaiz
      };
      if(children.length > 0){
        rootMenu.children = children;
      }
      return rootMenu;
    });
  }

  getAllSystemMenuRoots(ArrayGroup) {
    return ArrayGroup.reduce((accumulator, currentValue) => {
      currentValue?.systemMenus.forEach((menu) => {
        if (menu.systemMenu?.systemMenuRoot !== null) {
          accumulator.push(menu.systemMenu.systemMenuRoot);
        } else {
          const parent = {
            id: menu.systemMenu.id,
            description: menu.systemMenu.description,
            url: menu.systemMenu.url,
            icon: menu.systemMenu.icon,
          };
          accumulator.push(parent);
        }
      });
      return accumulator;
    }, []);
  }


}
