import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Order } from '../_models/order';
import { environment } from '../../environments/environment';

@Injectable()
export class OrderService {
    constructor(private http: HttpClient) { }

    getById(id: any) {
        return this.http.get<Order>(environment.apiUrl + 'orders/' + id).toPromise();
    }

    getByCompanyId(companyId: any) {
        return this.http.get<Order[]>(environment.apiUrl + 'orders?company_id=' + companyId);
    }

    getCountByCompanyIdAndStatusAndPeriod(companyId: any, status: string, from: string, to: string) {
        return this.http.get<Order[]>(environment.apiUrl + 'orders/count?company_id='
            + companyId + '&status=' + status + '&from=' + from + '&to=' + to);
    }

    getCountByCompanyIdAndPeriod(companyId: any, from: string, to: string) {
        return this.http.get<Order[]>(environment.apiUrl + 'orders/count?company_id='
            + companyId + '&from=' + from + '&to=' + to + '&status=');
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'orders/filter', filter).toPromise();
    }

    async getSaleResult(filter: any){
        return this.http.post<any[]>(environment.apiUrl + 'orderpackages/saleresult', filter).toPromise();
    }

    async getAdvertisingResultByCompanyAndPeriod(filter: any) {
        return await this.http.post<any>(environment.apiUrl + 'marketplaceadvertising/period', filter).toPromise();
    }

    async getYieldResultByCompanyAndPeriod(companyId: any, from: string, to: string) {
        return await this.http.get<any[]>(environment.apiUrl +
            'orders/yield?company_id=' + companyId + '&from=' + from + '&to=' + to).toPromise();
    }

    async getOrderResultByCompanyAndPeriod(companyId: any, from: string, to: string) {
        return await this.http.get<any[]>(environment.apiUrl +
            'orders/period?company_id=' + companyId + '&from=' + from + '&to=' + to).toPromise();
    }

    async getOrderResultByCompanyAndMonth(companyId: any, month: any, year: any) {
        return await this.http.get<any[]>(environment.apiUrl +
            'orders/result?company_id=' + companyId + '&month=' + month + '&year=' + year).toPromise();
    }

    async getOrderResultByCompanyAndYear(companyId: any, year: any) {
        return await this.http.get<any[]>(environment.apiUrl +
            'orders/year?company_id=' + companyId + '&year=' + year).toPromise();
    }

    async patchStatus(id: any, status: string) {
        return await this.http.patch<any[]>(environment.apiUrl + 'orders/' + id, {'status': status}).toPromise();
    }

    async updateCostPrice(id: number, orderId: number, costPrice: any) {
        return this.http.patch(environment.apiUrl + 'orders/' + orderId + '/items/' + id, {costPrice: costPrice}).toPromise();
    }

    async save(order: any){
        return await this.http.post<any[]>(environment.apiUrl + 'order', order).toPromise();
    }

    async update(order: any) {
        return await this.http.put<any[]>(environment.apiUrl + 'orders/' + order.id, order).toPromise();
    }

}
