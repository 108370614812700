import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { BankAccount } from '../_models/index';

@Injectable()
export class BankAccountService {
    constructor(private http: HttpClient) { }

    async getById(id: any) {
        return await this.http.get<BankAccount[]>(environment.apiUrl + 'bankaccounts/' + id).toPromise();
    }
    
    async getCurrentTotalById(id: any) {
        return await this.http.get<BankAccount[]>(environment.apiUrl + 'bankaccounts/' + id + "/currenttotal").toPromise();
    }

    async getByCompanyId(companyId: any) {
        return await this.http.get<BankAccount[]>(environment.apiUrl + 
            'bankaccounts?company_id=' + companyId + "&account_bank").toPromise();
    }

    async getByCompanyIdAndAccountBank(companyId: any, accountBank: any) {
        return await this.http.get<BankAccount[]>(environment.apiUrl + 
            'bankaccounts?company_id=' + companyId + "&account_bank=" + accountBank).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'bankaccounts/filter', filter).toPromise();
    }

    async save(bankAccount: any){
        return await this.http.post<any[]>(environment.apiUrl + 'bankaccounts', bankAccount).toPromise();
    }

    async transfer(bankAccountTargetId: any, bankAccountTransfer: any){
        return await this.http.post<any[]>(environment.apiUrl + 'bankaccounts/' + bankAccountTargetId + '/transfer', bankAccountTransfer).toPromise();
    }

    async delete(id: any) {
        return await this.http.delete<any>(environment.apiUrl + "bankaccounts/" + id).toPromise();
    }

    async updatePhysicalBalance(id: any, physicalBalance: number, blockedBalance: number) {
        const bankAccount = {'physicalBalance': physicalBalance, 'blockedBalance': blockedBalance};
        return await this.http.patch<any>(environment.apiUrl + "bankaccounts/" + id, bankAccount).toPromise();
    }
}
