import { Component, Inject, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../_services/authentication.service';
import { NotificationService } from '../../_services/notification.service';
import { AuthService } from '../../_guards/index';
import { Router } from '@angular/router';
import { Notification } from '../../_models/index';
import { Howl } from 'howler';
import { MenuHelper } from '../../_helpers/menu-helper';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnDestroy {

  public navItems: any;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public totalNotifications: any;
  public notifications: Notification[];
  public code: string;
  public userName: string;

  constructor(
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private router: Router,
    private menuHelper: MenuHelper,
    @Inject(DOCUMENT) _document?: any) {

      this.notifications = new Array();

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    this.userName = this.authService.getLoggedUser().name;
    this.navItems = this.menuHelper.getMenu().then( result =>{
      this.navItems = result;
    });;
  }

  playAudio() {
    let sound = new Howl({
      src: ['/assets/audio/notification.mp3']
        });

     sound.play()
  }

  async getUnreadNotifications() {
    const companyId = this.authService.getLoggedUser().company.id;
    this.notificationService.getByCompany(companyId)
      .first()
      .subscribe(
        async data => {
          data.forEach(n => {
            n['typeDescription'] = this.getNotificationTypeDescription(n.type);
            n['platformDescription'] = this.getPlatformDescription(n.platformId);
          });
          this.notifications = data;
          this.totalNotifications = data.length;
          if (data.length > 0) {
            this.playAudio();
          }
        }
    );
  }

  getNotificationTypeDescription(type: any) {
    switch(type){
      case 'NEW_ORDER':
        return "Nova venda";
      case 'PENDENCY':
        return "Pendência";
      case 'NEW_QUESTION':
        return "Nova pergunta";
      break;
    }
  }

  getPlatformDescription(type: any) {
    switch(type){
      case 0:
        return "Mercado Livre";
      break;
    }
  }

  visualize(notification: Notification) {
    this.notificationService.visualize(notification)
      .first()
      .subscribe(
        data => {
          this.router.navigate([data.link]);
        }
      )
  }

  clearNotifications() {
    const companyId = this.authService.getLoggedUser().company.id;
    this.notificationService.clearAll(companyId)
    .then(
      async data => {
        await this.getUnreadNotifications();
      }
    );
  }

  account() {
    this.router.navigate(['/account']);
  }
  goToCommunication() {
    this.router.navigate(['#/communication']);
  }

  logout(){
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  async ngOnInit() {
    await this.getUnreadNotifications();
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
