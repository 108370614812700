import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';

@Injectable()
export class DashboardService {
    constructor(private http: HttpClient) { }

    async encodeImage(imageUrl: string) {
        return await this.http.post<string>(environment.apiUrl + 'x8/encodeimage', imageUrl).toPromise();
    }

    async getByCompanyId(companyId: any) {
        return await this.http.get<any[]>(environment.apiUrl + 'x8/' + companyId + '/pendencies').toPromise();
    }

    async getDevolutionPendenciesByCompanyId(companyId: any) {
        return await this.http.get<any[]>(environment.apiUrl + 'devolutionpendencies/count/' + companyId).toPromise();
    }

    async getFinancialPendenciesByCompanyId(companyId: any) {
        return await this.http.get<any[]>(environment.apiUrl + 'pendencies/count/' + companyId).toPromise();
    }

    async getOrderItemPendenciesByCompanyId(companyId: any) {
        return await this.http.get<any[]>(environment.apiUrl + 'orderitempendencies/count/' + companyId).toPromise();
    }

    async getUnreadMessagesByCompanyId(companyId: any) {
        return await this.http.get<any[]>(environment.apiUrl + 'messages/count/' + companyId).toPromise();
    }

    async getUnansweredQuestionsByCompanyId(companyId: any) {
        return await this.http.get<any[]>(environment.apiUrl + 'questions/count/' + companyId).toPromise();
    }

    async getFinancialResultByCompanyAndPeriod(companyId: any, from: string, to: string) {
        return await this.http.get<any[]>(environment.apiUrl + 
            'financialmovements/period?company_id=' + companyId + '&from=' + from + '&to=' + to).toPromise();
    }
    
    async getFinancialResultByCompanyAndMonth(companyId: any, month: any, year: any) {
        return await this.http.get<any[]>(environment.apiUrl + 
            'financialmovements/result?company_id=' + companyId + '&month=' + month + '&year=' + year).toPromise();
    }

    async getFinancialResultByCompanyAndYear(companyId: any, year: any) {
        return await this.http.get<any[]>(environment.apiUrl + 
            'financialmovements/year?company_id=' + companyId + '&year=' + year).toPromise();
    }

    async getResultByCompanyAndYear(companyId: any, month: any,  year: any) {
        return await this.http.get<any[]>(environment.apiUrl + 
            'results?month=' + month + '&year=' + year + '&company_id=' + companyId).toPromise();
    }

    async doWizard(userId: any) {
        return await this.http.patch<any>(environment.apiUrl + 'login/' + userId, {firstAccess: false}).toPromise();
    }
}
