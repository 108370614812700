import { Company } from ".";

export class QuickAnswer {

    id: any;
    shortcut: string;
    text: string;
    color: string;
    active: any;
    company: Company;
    createdAt: any;
    updatedAt: any;
}