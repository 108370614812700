import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { StockTransfer } from '../_models/stock-transfer';

@Injectable()
export class StockService {
    constructor(private http: HttpClient) { }
    private sharedStockEdit: any;

    async getByFilter(filter: any){
      return await this.http.post<any[]>(environment.apiUrl + 'stocks/filter', filter).toPromise();
    }
    async stocksTransfer(stock: StockTransfer){
      return await this.http.post<StockTransfer>(environment.apiUrl + 'stocks/transfer', stock).toPromise();
    }
    async save(stock: any){
      return await this.http.post<any>(environment.apiUrl + 'stocks', stock).toPromise();
  }
  setSharedStockEdit(stock: any) {
    this.sharedStockEdit = stock;
  }
  getSharedStockEdit() {
    return this.sharedStockEdit;
  }
  async edit(idStock: number, stock: any) {
    return await this.http
      .put<any[]>(environment.apiUrl + `stocks/${idStock}`, stock)
      .toPromise();
  }
}
