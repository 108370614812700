<div class="modal-dialog modal-lg custom-modal-dialog d-flex justify-content-center align-items-center" role="document" (click)="closeModal()">
    <div class="modal-content custom-modal-content">
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div *ngIf="announcements?.length > 0"> 
                        <table class="table custom-table">
                            <thead>
                                <tr class="custom-table-header">
                                    <th>Plataforma</th>
                                    <th>Conta</th>
                                    <th>Anúncio</th>
                                    <th>Estoque</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let record of announcements" class="custom-table-row"> 
                                    <td>{{ record.announcement.marketplaceSource?.platform }}</td> 
                                    <td>{{ record.announcement.marketplaceSource?.nickname }}</td> 
                                    <td>{{ record.announcement.announcement.marketplaceReference }}</td> 
                                    <td>{{ record.announcement.fulfillmentStock !== null ? record.announcement.fulfillmentStock : 'Não possui' }}</td> 
                                </tr>
                            </tbody>
                        </table> 
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="row">
                <div class="col-md-12 box-button-right">
                    <button (click)="bsModalRef.hide()" class="btn btn-ghost-secondary">Fechar</button>
                </div>
            </div>
        </div>
    </div>
</div>