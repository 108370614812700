import { NgModule } from '@angular/core';
import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { AlertModule } from 'ngx-bootstrap/alert';
import { LoadingModule } from './../loading/loading.module';
import { ProgressComponent } from '../progress/progress.component';

defineLocale('pt-br', ptBrLocale);

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    BsDropdownModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    ReactiveFormsModule,
    LoadingModule    
  ],
  declarations: [ 
    ProgressComponent
  ],
  exports: [
    ProgressComponent
  ]
})
export class ProgressModule { }
