import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { Company } from '../_models/index';

@Injectable()
export class CompanyService {
    constructor(private http: HttpClient) { }

    save(company: Company) {
        return this.http.post<Company[]>(environment.apiUrl + 'companies', company).toPromise();
    }
}
