import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { PaymentMethod } from '../_models/index';

@Injectable()
export class PaymentMethodService {
    constructor(private http: HttpClient) { }

    async getByCompanyId(companyId: any) {
        return await this.http.get<PaymentMethod[]>(environment.apiUrl + 'paymentmethods/all?company_id=' + companyId).toPromise();
    }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'paymentmethods/filter', filter).toPromise();
    }

    async save(paymentMethod: any){
        return await this.http.post<any[]>(environment.apiUrl + 'paymentmethods', paymentMethod).toPromise();
    }

    async changeActive(id: any, paymentMethod: PaymentMethod) {
        return await this.http.patch<any>(environment.apiUrl + "paymentmethods/" + id, paymentMethod).toPromise();
    }
    async getCountByFilter(filter: any) {
        return await this.http.post<any>(environment.apiUrl + "paymentmethods/count", filter).toPromise();
    }
}
