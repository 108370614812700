import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { environment } from '../../environments/environment';
import { PurchaseOrderItem } from '../_models/purchase-order-item';

@Injectable()
export class PurchaseOrdersService {
    constructor(private http: HttpClient) { }
    private sharedOrderEdit: any;

    async edit(order: any, id: number){
      return await this.http.put<any[]>(environment.apiUrl + `purchaseorders/${id}`, order).toPromise();
    }
    async save(order: any){
      return await this.http.post<any[]>(environment.apiUrl + 'purchaseorders', order).toPromise();
    }
    async deleteOrder(purchaseOrderId: number){
      return await this.http.delete<any[]>(environment.apiUrl + `purchaseorders/${purchaseOrderId}`).toPromise();
    }
    async saveItemPurchaseOrder(itemBody: any, purchaseOrderId: number){
      return await this.http.post<PurchaseOrderItem[]>(environment.apiUrl + `purchaseorders/${purchaseOrderId}/items`, itemBody).toPromise();
    }
    async deleteItemPurchaseOrder(purchaseOrderId: number, idItem: number){
      return await this.http.delete<any[]>(environment.apiUrl + `purchaseorders/${purchaseOrderId}/items/${idItem}`).toPromise();
    }
    async editItemOrder(itemBody: PurchaseOrderItem, purchaseOrderId: number, idItem: number){
      return await this.http.put<PurchaseOrderItem[]>(environment.apiUrl + `purchaseorders/${purchaseOrderId}/items/${idItem}`,itemBody).toPromise();
    }
    async getByFilterOrders(filter: any){
      return await this.http.post<any[]>(environment.apiUrl + 'purchaseorders/filter', filter).toPromise();
    }
    async getCountByFilter(filter: any){
      return await this.http.post<any[]>(environment.apiUrl + 'purchaseorders/count', filter).toPromise();
  }
    setSharedOrderEdit(order: any) {
      this.sharedOrderEdit = order;
    }
    getSharedOrderEdit() {
      return this.sharedOrderEdit;
    }
}
