import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Warning } from '../../_models/warning';

@Component({
  selector: 'app-alert-system',
  templateUrl: './alert-system.component.html',
  styleUrls: ['./alert-system.component.scss']
})
export class AlertSystemComponent implements OnInit {

  @Input() dataWarnings: Warning[];
  @Output() closeAlert = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {

  }
  closeAlertParent(id: number){
    this.closeAlert.emit(id);
  }
}
