import { Order } from './order';
import { Purchase } from './purchase';

export class StockMovement {

    id: any;
    sku: string;
    date: string;
    quantity: any;
    type: any;
    subType: any;
    note: string;
    order: Order;
    purchase: Purchase;
    balance: any;
}