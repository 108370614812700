import { BankAccount } from './bank-account';
import { Company } from './company';
import { Customer } from './customer';
import { FinancialAccount } from './financial-account';
import { Supplier } from './supplier';

export class FinancialMovement {

    id: any;
    movementDate: any;
    numberMovement: string;
    compensationDate: any;
    expirationDate: any;
    type: any;
    paymentMethod: any;
    buyNote: any;
    customerDocument: string;
    providerDocument: string;
    marketplaceOrder: string;
    marketplaceMovement: string;
    note: string;
    value: any;
    bankAccountId: any;
    financialAccount: FinancialAccount;
    bankAccount: BankAccount;
    packId: string;
    supplier: Supplier;
    customer: Customer;
    installmentNumber: any;
    originType: string;
    company: Company;
    discount: any;
    interest: any;
    financialMovements: FinancialMovement[];
}