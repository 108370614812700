<div class="app-body x8-background">
  <main class="main">
    <div class="row row-login">
      <div class="col-md-4 offset-md-1">
        <div class="row" id="div-logo">
          <div class="col-md-4">
            <img src="assets/img/brand/logo.png" width="250" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-10">
            <div class="card" *ngIf="success" id="card-email-forgotpass">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <img src="./../../../assets/img/change-password.svg" width="350">
                  </div>
                </div><br />
                <div class="row">
                  <div class="col-md-12">
                    <h4>Ótimo! Senha alterada com sucesso.</h4>
                  </div>
                </div>
                <div class="row" id="link-login">
                  <div class="col-md-12 box-button-right">
                    <span class="link" (click)="onReset()">Login</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-group" id="card-changepass" *ngIf="!success">
              <div class="card">
                <div class="card-body">
                  <form #f="ngForm" name="form" [formGroup]="changePassForm" (ngSubmit)="onSubmit()" novalidate>
                    <h1>Nova senha</h1>
                    <p class="text-muted">Informe sua nova senha</p>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="icon-lock"></i></span>
                      </div>
                      <input [type]="showPassword ? 'text' : 'password'" formControlName="password" class="form-control form-control-lg" placeholder="Nova senha" 
                        [ngClass]="{ 'is-invalid': submitted && changePassForm.controls.password.errors }">
                      <span class="input-group-text" (click)="togglePasswordVisibility()">
                        <i [class]="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                      </span>
                    </div>
                    <div *ngIf="submitted && changePassForm.controls.password.errors" class="invalid-input">
                      <div *ngIf="changePassForm.controls.password.errors.required">O campo Nova senha é obrigatório</div>
                      <div *ngIf="changePassForm.controls.password.errors.minlength">A nova senha deve conter no mínimo 8 caractéres</div>
                      <div *ngIf="changePassForm.controls.password.errors.pattern">
                        A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial
                      </div>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-check"></i></span>
                    </div>
                    <input [type]="showConfirmPassword ? 'text' : 'password'" formControlName="confirmPassword" class="form-control form-control-lg" placeholder="Confirme a senha" 
                      [ngClass]="{ 'is-invalid': submitted && changePassForm.controls.confirmPassword.errors }">
                    <span class="input-group-text" (click)="toggleConfirmPasswordVisibility()">
                      <i [class]="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                    </span>
                  </div>
                    <div *ngIf="submitted && changePassForm.controls.confirmPassword.errors" class="invalid-input">
                        <div *ngIf="changePassForm.controls.confirmPassword.errors.required">O campo Confirme a senha é obrigatório</div>
                        <div *ngIf="changePassForm.controls.confirmPassword.errors.mustMatch">Deve ser igual ao campo Nova senha</div>
                    </div>                               
                    <div class="row">
                      <div class="col-6">
                        <button class="btn btn-success btn-lg" [disabled]="disableBtnEnviar">Salvar</button>                        
                      </div>
                      <div class="col-6 box-button-right">
                        <span class="link" (click)="onReset()">Cancelar</span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 box-login-presentation"></div>
    </div>
  </main>
</div>

<div bsModal #loadingModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm" role="document">
    <div class="modal-content" id="loading-content">
      <div class="modal-body">
        <img src="assets/img/loading.gif" />
      </div>
    </div>
  </div>
</div>