import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";

@Injectable()
export class UsersGroupService {
  constructor(private http: HttpClient) {}

  async filterGroups(filter: any) {
    return await this.http
      .post<any[]>(environment.apiUrl + `usersgroups/filter`, filter)
      .toPromise();
  }
  async saveGroup(bodyGroup: any) {
    return await this.http
      .post<any[]>(environment.apiUrl + `usersgroups`, bodyGroup)
      .toPromise();
  }
  async getAllUsers(idCompany: number) {
    return await this.http
      .get<any[]>(environment.apiUrl + `users?company_id=${idCompany}`)
      .toPromise();
  }
  async getMenus() {
    return await this.http.get<any[]>(environment.apiUrl + `menus`).toPromise();
  }
  async changeStatusGroup(idGroup: number, statusGroup: any) {
    return await this.http
      .patch<any[]>(environment.apiUrl + `usersgroups/${idGroup}`, statusGroup)
      .toPromise();
  }
  async editGroup(idGroup: number, bodyGroup: any) {
    return await this.http
      .put<any[]>(environment.apiUrl + `usersgroups/${idGroup}`, bodyGroup)
      .toPromise();
  }
  async editGroupMenus(userGroupId: number, bodyEditMenu: any) {
    return await this.http
      .post<any[]>(
        environment.apiUrl + `usersgroups/${userGroupId}/menus`,
        bodyEditMenu
      )
      .toPromise();
  }
  async deleteGroupMenus(userGroupId: number, menuId: any) {
    return await this.http
      .delete<any[]>(
        environment.apiUrl + `usersgroups/${userGroupId}/menus/${menuId}`
      )
      .toPromise();
  }
}
