<div class="app-body x8-background">
  <main class="main">      
      <div class="row row-login">
        <div class="col-md-4 offset-md-1">
          <div class="row" id="div-logo">
            <div class="col-md-4">
              <img src="assets/img/brand/logo.png" width="250" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-10">
              <div class="card" id="card-email-verified" *ngIf="verificationError">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <img src="./../../../assets/img/error_verification_link.svg" width="350">
                    </div>
                  </div><br />
                  <div class="row">
                    <div class="col-md-12">
                      <h4>Erro ao verificar seu e-mail</h4>
                      <p class="text-explanation">O link de verificação é inválido! Por favor, entre em contato conosco!</p>
                    </div>
                  </div>
                  <div class="row" id="link-login">
                    <div class="col-md-12 box-button-right">
                      <span class="link" (click)="onReset()">Login</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card" id="card-email-verified" *ngIf="verificated">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <img src="./../../../assets/img/email-verification-success.png" width="350">
                    </div>
                  </div><br />
                  <div class="row">
                    <div class="col-md-12">
                      <h4>E-mail verificado com sucesso</h4>
                      <p class="text-explanation">Seu e-mail foi verificado com sucesso, agora pode logar e utilizar a Plataforma X8!</p>
                    </div>
                  </div>
                  <div class="row" id="link-login">
                    <div class="col-md-12 box-button-right">
                      <span class="link" (click)="onReset()">Login</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-group" *ngIf="!verificated && !verificationError">
                <div class="card p-2" id="card-login">
                  <div class="card-body">
                    <form [formGroup]="loginForm" name="form" (ngSubmit)="login()" novalidate>
                      <h1>Login</h1>
                      <p class="text-muted">Acesse sua conta</p>
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-user"></i></span>
                        </div>
                        <input type="text" class="form-control form-control-lg" placeholder="Login" autocomplete="login" name="login"
                          formControlName="login" [ngClass]="{ 'is-invalid': submitted && loginForm.controls.login.errors }">
                        <div *ngIf="submitted && loginForm.controls.login.errors" class="invalid-input">
                          <div *ngIf="loginForm.controls.login.errors.required">O campo E-mail é obrigatório</div>                    
                        </div>
                      </div>
                      <div class="input-group mb-4">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="icon-lock"></i></span>
                        </div>
                        <input type="password" class="form-control form-control-lg" placeholder="Senha" autocomplete="current-password" name="password" 
                          formControlName="password" [ngClass]="{ 'is-invalid': submitted && loginForm.controls.password.errors }">
                        <div *ngIf="submitted && loginForm.controls.password.errors" class="invalid-input">
                          <div *ngIf="loginForm.controls.password.errors.required">O campo Senha é obrigatório</div>                    
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <button type="submit" [disabled]="btnLoginDisabled" class="btn btn-success btn-lg px-4">Acessar
                            <i *ngIf="btnLoginDisabled" class="fa fa-circle-o-notch fa-spin"></i>  
                          </button>
                        </div>
                        <div class="col-6 box-button-right">                      
                          <span class="link" (click)="forgotPassword()">Esqueceu sua senha?</span>
                        </div>
                        <div class="col-6 text-right" *ngIf="false">
                          <button type="button" class="btn btn-link px-0" (click)="forgotPassword()">Forgot password?</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" id="box-register">
            <div class="col-md-12">
              <p class="register-question">Ainda não utiliza a Plataforma X8?</p>
              <span class="link" (click)="register()">Cadastre-se e experimente por 14 dias grátis</span>
            </div>
          </div>
        </div>
        <div class="col-md-7 box-login-presentation"></div>
      </div>
  </main>
</div>
