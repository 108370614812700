import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }

  async getAllUsers( idCompany: number ){
      return await this.http.get<any[]>(environment.apiUrl + `users?company_id=${idCompany}`).toPromise();
  }
  async saveUser(user: any){
      return await this.http.post<any[]>(environment.apiUrl + `users`, user).toPromise();
  }
  async editUser(user: any, id: number){
    return await this.http.put<any[]>(environment.apiUrl + `users/${id}`, user).toPromise();
  }
  async deleteUser(id: number){
    return await this.http.delete<any[]>(environment.apiUrl + `users/${id}`).toPromise();
  }
  async getUserById(id: number){
    return await this.http.get<any[]>(environment.apiUrl + `users/${id}`).toPromise();
  }
  async getCountByFilter(filter: any){
    return await this.http.post<any[]>(environment.apiUrl + 'users/count', filter).toPromise();
  }
  async filterAllUsers(filter: any){
    return await this.http.post<any[]>(environment.apiUrl + 'users/filter', filter).toPromise();
  }

  verifyTwoFactorCode(user: any, code: string) {
    const body = {
        twoFactorAuthCode: code
    };
    return this.http.post<any>(`${environment.apiUrl}/users/twoFactorAuth/${user.id}`, body);
  }

}
