import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Devolution } from '../_models';

@Injectable()
export class DevolutionService {
    constructor(private http: HttpClient) { }

    async getByFilter(filter: any){
        return await this.http.post<any[]>(environment.apiUrl + 'devolutions/filter', filter).toPromise();
    }

    async confirm(devolution: Devolution) {
        return await this.http.patch<any>(environment.apiUrl + "devolutions/" + devolution.id + "/true", devolution).toPromise();
    }

    async confirmNotReceived(id: any) {
        return await this.http.patch<any>(environment.apiUrl + "devolutions/" + id + "/false", {}).toPromise();
    }
}