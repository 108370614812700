import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, CommonModule, PathLocationStrategy } from '@angular/common';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgxMaskModule } from 'ngx-mask';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

import { AuthGuard, AuthErrorHandler, AuthService, RouteGuard, TwoFactorAuthGuard } from './_guards/index';
import { JwtInterceptor } from './_helpers/index';
import { AuthenticationService, UserService, NotificationService, OrderService, FinancialMovementService, PendencyService, FinancialMovementTypeService, FinancialAccountService, UploadFileService, OrderPackageService, SupplierService, ProductService, CategoryService, AnnouncementService, BankAccountService, ReportService, CompanyService, DevolutionService, StockMovementService, DevolutionPendencyService, PaymentMethodService, DashboardService, FinancialMovementTypeGroupService, OrderTransactioControlService, BillingService, QuickAnswerService, CommunicationService, X8Service } from './_services/index';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { ForgotPasswordComponent } from './views/login/forgotpassword.component';
import { ChangePasswordComponent } from './views/login/changepassword.component';
import { Login2FAComponent } from './views/login/two-factor-auth.component';
import { RegisterComponent } from './views/register/register.component';

import { UploadFileModule } from './components/upload-file/upload-file.module';

const APP_CONTAINERS = [
  DefaultLayoutComponent,
  FullLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule, BsDropdownToggleDirective } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { MarketplaceAccountService } from './_services/marketplace-account.service';
import { DropFileDirective } from './_directives/dropfile/dropfile.directive';
import { ProgressModule } from './views/progress/progress.module';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CollapsesComponent } from './views/base/collapses.component';
import { TooltipsComponent } from './views/base/tooltips.component';
import { PurchaseService } from './_services/purchase.service';
import { OrderItemPendencyService } from './_services/order-item-pendency.service';
import { CustomerService } from './_services/customer.service';
import { ReturnPurchaseService } from './_services/returnpurchase.service';
import { QuestionService } from './_services/question.service';
import { MessageService } from './_services/message.service';
import { AutomaticMessageService } from './_services/automatic-message.service';
import { PagerService } from './components/pager/pager-service';
import { GroupFinancialMovementService } from './_services/group-financial-movement.service';
import { FinancialBillingService } from './_services/financialbilling.service';
import { FinancialMovementReportControlService } from './_services/financial-movement-report-control.service';
import { ColorPickerModule } from 'ngx-color-picker';
import { ReferralCouponService } from './_services/referral-coupon.service';
import { InvoicesService } from './_services/invoices.service';
import { AssetsHelper } from './_helpers/assets.helper';
import { DigitalCertificateService } from './_services/digital-certificate.service';
import { DigitalCertificatesService } from './_services/digitalcertificates.service';
import { CompanyPreferencesService } from './_services/company-preference.service';
import { FullLayoutComponent } from './containers/full-layout/full-layout.component';
import { MenuHelper } from './_helpers/menu-helper';
import { UsersGroupService } from './_services/usersGroup.service';
import { CepService } from './_services/cep.service';
import { ContentWizardModule } from './components/content-wizard/content-wizard.module';
import { PrinterService } from './_services/printer.service';
import { AlertSystemModule } from './components/alert-system/alert-system.module';
import { WarningsService } from './_services/warnings.service';
import { PurchaseOrdersService } from './_services/purchase-orders.service';
import { StockService } from './_services/stock.service';
import { DeniedRoutingModule } from './views/denied/denied-routing.module';
import { DeniedModule } from './views/denied/denied.module';
import { BankDraftService } from './_services/bank-draft.service';
import { StockFulfillmentComponent } from './views/stockmovement/stock-fulfillment/stock-fulfillment.component';
import { BankDraftHolderService } from './_services/bank-draft-holder.service';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(),
    ProgressModule,
    UploadFileModule,
    ColorPickerModule,
    ContentWizardModule,
    AlertSystemModule,
    DeniedModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    Login2FAComponent,
    RegisterComponent,
    DropFileDirective,
    CollapsesComponent,
    TooltipsComponent,
    StockFulfillmentComponent
  ],
  providers: [
    AnnouncementService,
    AuthGuard,
    TwoFactorAuthGuard,
    RouteGuard,
    AuthenticationService,
    BankAccountService,
    CategoryService,
    CompanyService,
    CustomerService,
    DevolutionService,
    DevolutionPendencyService,
    DigitalCertificateService,
    FinancialAccountService,
    FinancialMovementService,
    FinancialMovementTypeService,
    FinancialMovementTypeGroupService,
    FinancialBillingService,
    GroupFinancialMovementService,
    MarketplaceAccountService,
    NotificationService,
    OrderService,
    OrderPackageService,
    OrderItemPendencyService,
    PaymentMethodService,
    PendencyService,
    PurchaseService,
    ProductService,
    ReferralCouponService,
    ReportService,
    StockMovementService,
    SupplierService,
    UserService,
    DashboardService,
    ReturnPurchaseService,
    QuestionService,
    MessageService,
    AutomaticMessageService,
    OrderTransactioControlService,
    BillingService,
    FinancialMovementReportControlService,
    QuickAnswerService,
    PagerService,
    CommunicationService,
    InvoicesService,
    AssetsHelper,
    X8Service,
    DigitalCertificatesService,
    CompanyPreferencesService,
    MenuHelper,
    CepService,
    UsersGroupService,
    BankDraftService,
    BankDraftHolderService,
    PrinterService,
    WarningsService,
    PurchaseOrdersService,
    StockService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: ErrorHandler,
      useClass: AuthErrorHandler
    },
    AuthService,
    UploadFileService,
    UploadFileComponent,
    ThemeService
  ],
  bootstrap: [ AppComponent ],
  entryComponents: [ StockFulfillmentComponent ]
})
export class AppModule { }
