import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Order } from '../_models/order';
import { FinancialMovement } from '../_models/financial-movement';
import { environment } from '../../environments/environment';
import { CostPieChart } from '../_models/cost-pie-chart';
import { FinancialAccount } from '../_models';

@Injectable()
export class FinancialAccountService {
    constructor(private http: HttpClient) { }   

    getByCompanyId(companyId: any){
        return this.http.get<FinancialAccount[]>(environment.apiUrl + 'financialaccounts?company_id=' + companyId);
    }
}