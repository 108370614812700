import { FinancialMovementType } from "./financial-movement-type";

export class Supplier {

    id: any;
    cpfCnpj: string;
    name: string;
    tradingName: string;
    email: string;
    phone: string;
    cellphone: string;
    person: string;
    address: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    active: any;
    financialMovementType: FinancialMovementType;
}